import React from "react"
import { type BaseFunction } from "src/types"

/**
 * This is a user land implementation of useEvent. This will later be replaced with something like
 * useEffectEvent in react. At the time of writing, useEffectEvent is documented but hasn't undergone
 * the react RFC process.
 *
 * @see https://react.dev/learn/separating-events-from-effects#limitations-of-effect-events
 */
const useRefCallback = <T extends BaseFunction | null | undefined>(
  callback: T
) => {
  const ref = React.useRef<T | null>(null)
  React.useEffect(() => {
    ref.current = callback
  }, [callback])

  // This ref shouldn't be called/read during render. It's unlikely to be null
  // when called. If it is null, then it will probably throw in development.
  return ref as React.MutableRefObject<T>
}
export default useRefCallback
