import React from "react"
import { useNavigate, Navigate, useLocation } from "react-router-dom"
import { isEmptyObject } from "src/functions/is-empty-object"
import { sleepIfNeeded } from "src/functions/sleep"
import useInterval from "src/hooks/use-interval"
import usePrevious from "src/hooks/use-previous"
import useStore, { Setters } from "src/hooks/use-store"
import { createReducer } from "src/functions/create-reducer"
import { reduceReducers } from "src/functions/reduce-reducers"
import useRefCallback from "src/hooks/use-ref-callback"
import AlertModal from "src/components/deprecated-alert-modal"
import { getErrorCode } from "src/functions/get-error-code"
import useForceUpdate from "src/hooks/use-force-update"
import { Reducer } from "src/types"
import { GraphQLError } from "graphql/error"

interface UseApiState<Data> {
  isFetching: boolean
  called: boolean
  args: unknown[]
  errors: object
  validationErrors: object
  data: Data
  modalKey: string
}

const myReducer = createReducer<UseApiState<object>>({
  SET_MODAL_KEY: (state, action) => ({
    ...state,
    modalKey: action.modalKey,
  }),
  START: (state, action) => {
    return {
      ...state,
      args: action.args,
      isFetching: true,
      errors: null,
      validationErrors: {},
    }
  },
  FINISH_SUCCESS: (state, action) => {
    return {
      ...state,
      data: action.data,
      errors: null,
      isFetching: false,
      called: true,
      validationErrors: {},
      ...(action.flattenData ? action.data : {}),
    }
  },
  FINISH_ERROR: (state, action) => {
    return {
      ...state,
      data: action.persistDataOnError ? state.data : null,
      errors: action.errors,
      called: true,
      isFetching: false,
    }
  },
  SET_DATA: (state, action) => {
    return {
      ...state,
      data: action.data,
    }
  },
  FINISH_VALIDATION_ERRORS: (state, action) => {
    return {
      ...state,
      validationErrors: action.validationErrors,
      called: true,
      errors: null,
    }
  },
  RESET_TO_INITIAL_STATE: (state, action) => {
    return {
      ...action.initialState,
      data: null,
      errors: null,
      isFetching: false,
      called: false,
    }
  },
})
const UseApiContext = React.createContext<{
  forceUpdate: () => void
  modalConfig: ErrorObject | undefined
  onGlobalError: (errors?: Errors, apiName?: string) => void
  validate: (...args: object[]) => Error | Record<string, never>
  setApiProviderErrorCode: (errorCode: string) => void
}>({
  forceUpdate: () => {},
  modalConfig: undefined,
  onGlobalError: () => {},
  validate: () => ({}),
  setApiProviderErrorCode: () => {},
})

interface ErrorObject
  extends React.ComponentPropsWithoutRef<typeof AlertModal> {
  redirect?: string | { pathname: string; search: string }
}

type Error<Metadata = object> =
  | (({
      setErrorCode,
      metadata,
      apiName,
    }: {
      setErrorCode?: (errorCode: string | null) => void
      metadata: Metadata
      apiName?: string
    }) => ErrorObject)
  | ErrorObject
export type Errors<Metadata = object> = {
  [errorCode: string]: Error<Metadata>
} & { graphQLErrors?: GraphQLError[] }

interface ProviderProps<Metadata extends object> {
  errors: Errors<Metadata>
  validate?: (args?: object) => Error<object> | Record<string, never>
  children:
    | React.ReactChild[]
    | React.ReactChild
    | (({
        setApiProviderErrorCode,
        apiProviderErrorCode,
      }: {
        setApiProviderErrorCode: (
          errorCode: string,
          { metadata }: { metadata?: object }
        ) => void
        apiProviderErrorCode: string
      }) => React.ReactChild)
}

export const UseApiProvider = <Metadata extends object>(
  props: ProviderProps<Metadata>
) => {
  const errors = props.errors ?? {}
  const validate = props.validate ?? (() => ({}))
  const forceUpdate = useForceUpdate()
  const { useSelector, setters } = useStore<{
    errorCode: string | null
    metadata: object | null | undefined
    apiName: string | null | undefined
  }>({
    initialState: {
      errorCode: null,
      metadata: null,
      apiName: null,
    },
    setters: (set) => ({
      setErrorCode: (
        errorCode: string,
        { metadata, apiName }: { metadata?: object; apiName?: string } = {}
      ) => {
        set({ errorCode, metadata, apiName })
      },
    }),
  })
  const { errorCode, metadata, apiName } = useSelector((state) => ({
    errorCode: state.errorCode,
    metadata: state.metadata,
    apiName: state.apiName,
  }))
  let modalConfig = metadata?.errorKey ? errors[metadata.errorKey] : null
  if (!modalConfig) {
    modalConfig = errors[errorCode] ?? null
  }

  if (typeof modalConfig === "function") {
    modalConfig = modalConfig({
      setErrorCode: setters.setErrorCode,
      metadata,
      apiName,
    })
  }

  if (modalConfig?.redirect) {
    return <Navigate to={modalConfig.redirect} />
  }
  const value = {
    setApiProviderErrorCode: setters.setErrorCode,
    metadata,
    errorCode,
    forceUpdate,
    modalConfig,
    onGlobalError: (errorsArgs?: Errors, apiName?: string) => {
      // Try to parse the error code from the network.
      // If it's there, then use it, otherwise use the word fallback
      const errorCode = getErrorCode(errorsArgs as any) ?? "fallback"
      // Is the errorCode from the network in the errors prop?
      // For example, did a 503 return, but errors does not cover 503s? Then use the fallback
      let metadata = errorsArgs?.graphQLErrors?.[0]?.extensions
      if (!metadata) {
        try {
          metadata = JSON.parse(JSON.stringify(errorsArgs))?.response
            ?.errors?.[0]?.extensions
        } catch {}
      }
      setters.setErrorCode(
        errors[errorCode as string] ? errorCode : "fallback",
        {
          metadata,
          apiName,
        }
      )
    },
    validate,
  }

  return (
    <UseApiContext.Provider value={value}>
      {modalConfig && <AlertModal {...modalConfig} />}
      {typeof props.children === "function"
        ? props.children({
            setApiProviderErrorCode: setters.setErrorCode,
            apiProviderErrorCode: value.errorCode,
          })
        : props.children}
    </UseApiContext.Provider>
  )
}

export const useApiContext = ({
  onModalChange = () => {},
}: { onModalChange?: (modalConfig?: ErrorObject) => void } = {}) => {
  const context = React.useContext(UseApiContext)
  const onModalChangeRef = useRefCallback(onModalChange)

  React.useEffect(() => {
    onModalChangeRef.current(context.modalConfig)
  }, [context.modalConfig])
  return context
}

interface UseApiProps<State, Data> {
  modals?: Record<
    string,
    | React.ComponentProps<typeof AlertModal>
    | (() => React.ComponentProps<typeof AlertModal>)
    | React.ReactElement<unknown>
  >
  blockReinvoke?: boolean
  flattenData?: boolean
  runGlobalErrorHandler?: boolean
  runGlobalValidate?: boolean
  returnState?: boolean
  actions?: object
  interval?: number
  intervalActive?: boolean
  /** @deprecated */
  onStart?: () => object | void
  /** @deprecated */
  onFinish?: (success: boolean) => object | void
  validate?: (...args: any) => object
  /** @deprecated */
  onError?: (error: object) => void
  /** @deprecated */
  onSuccess?: (data: Data) => void
  setters?: Setters<State & UseApiState<Data>>
  lazy?: boolean
  persistDataOnError?: boolean
  reducer?: Reducer
  initialState?: State & { data?: Data }
  redirectOnUnauthorized?: boolean
  throwErrors?: boolean
  time?: number
  sleepOnError?: boolean
  name?: string
  shouldCallInterval?: () => boolean
}

const useApi = <ApiCall extends (...args: any[]) => void, State extends object>(
  apiCall: ApiCall,
  {
    modals = undefined,
    blockReinvoke = false,
    flattenData = false,
    runGlobalErrorHandler = true,
    runGlobalValidate = true,
    returnState = true,
    actions: _actions,
    interval = 0,
    intervalActive = true,
    onStart = () => ({}),
    onFinish = () => ({}),
    validate = () => ({}),
    onError = () => {},
    onSuccess = () => {},
    setters: _setters = () => ({}),
    lazy = true,
    persistDataOnError = false,
    reducer = (state: object) => state,
    initialState = {} as State,
    redirectOnUnauthorized = false,
    throwErrors = false,
    time = 0,
    sleepOnError = true,
    name,
    shouldCallInterval = () => true,
  }: UseApiProps<State, Awaited<ReturnType<ApiCall>>> = {}
) => {
  type Data = Awaited<ReturnType<ApiCall>>
  const {
    forceUpdate,
    onGlobalError,
    validate: globalValidate,
  } = useApiContext()
  const globalValidateRef = useRefCallback(globalValidate)
  const onGlobalErrorRef = useRefCallback(onGlobalError)
  const forceUpdateRef = useRefCallback(forceUpdate)
  const apiCallRef = useRefCallback(apiCall)
  const onStartRef = useRefCallback(onStart)
  const onFinishRef = useRefCallback(onFinish)
  const onErrorRef = useRefCallback(onError)
  const onSuccessRef = useRefCallback(onSuccess)
  const validateRef = useRefCallback(validate)
  const shouldCallIntervalRef = useRefCallback(shouldCallInterval)
  const location = useLocation()
  const navigate = useNavigate()
  const { dispatch, useSelector, actions, setters, getState, set } = useStore<
    State & { data?: Data }
  >({
    reducer: reduceReducers(myReducer, reducer),
    initialState: {
      ...initialState,
      data: null,
      errors: null,
      isFetching: false,
      called: false,
      modalKey: "",
    } as unknown as State & UseApiState<Data>,
    name,
    setters: (set, get) => {
      return {
        ..._setters(set, get as any),
        resetToInitialState: () => set(initialState),
      }
    },
    actions: {
      ..._actions,
      setModalKey: (modalKey: string) => ({
        type: "SET_MODAL_KEY",
        modalKey,
      }),
    },
  })

  const isFetching = useSelector(
    (state) => (state as unknown as UseApiState<Data>).isFetching
  )
  const modalKey = useSelector(
    (state) => (state as unknown as UseApiState<Data>).modalKey
  )

  const called = useSelector(
    (state) => (state as unknown as UseApiState<Data>).called
  )
  const args = useSelector(
    (state) => (state as unknown as UseApiState<Data>).args
  )
  const errors = useSelector(
    (state) => (state as unknown as UseApiState<Data>).errors
  )
  const validationErrors = useSelector(
    (state) => (state as unknown as UseApiState<Data>).validationErrors
  )

  const sleepIfNecessary = React.useCallback(
    async (start: number | Date) => {
      if (time) {
        await sleepIfNeeded(time, start)
      }
    },
    [time]
  )
  const canInvoke = () => {
    return blockReinvoke ? !isFetching : true
  }
  // Wrap canInvoke in a ref so that invoke is not
  // re-initialized whenever isFetching changes
  const canInvokeRef = useRefCallback(canInvoke)

  const invoke = React.useCallback(
    async (...args: any) => {
      if (!canInvokeRef?.current?.()) {
        return
      }
      const start = Date.now()
      try {
        ;(onStartRef.current as any)(...args)
        let errors = validateRef.current(...args)
        if (runGlobalValidate) {
          errors = { ...errors, ...globalValidateRef.current(...args) }
        }
        if (!isEmptyObject(errors)) {
          dispatch({
            type: "FINISH_VALIDATION_ERRORS",
            validationErrors: errors,
          })
          return errors
        } else {
          dispatch({ type: "START", args })
          const data = await apiCallRef.current(...args)
          await sleepIfNecessary(start)
          dispatch({ type: "FINISH_SUCCESS", data, flattenData })
          onSuccessRef.current(data as Data)
          onFinishRef.current(true)
          return data
        }
      } catch (errors) {
        if (sleepOnError) {
          await sleepIfNecessary(start)
        }
        const message: string = (errors as any)?.message ?? ""
        if (
          redirectOnUnauthorized &&
          (message === "Unauthorized" || message.indexOf("Unauthorized") !== -1)
        ) {
          navigate(
            {
              pathname: `/login`,
              search: location.search,
            },
            {
              state: { from: location.pathname },
            }
          )
        }
        dispatch({ type: "FINISH_ERROR", errors, persistDataOnError })
        onErrorRef.current(errors as Errors)
        if (runGlobalErrorHandler) {
          onGlobalErrorRef.current(errors as Errors, name)
          forceUpdateRef.current()
        }
        onFinishRef.current(false)
        if (throwErrors) {
          throw errors
        }
        return errors
      }
    },
    [
      name,
      flattenData,
      runGlobalValidate,
      dispatch,
      sleepIfNecessary,
      sleepOnError,
      redirectOnUnauthorized,
      persistDataOnError,
      throwErrors,
      runGlobalErrorHandler,
      navigate,
      location,
    ]
  )
  const retry = React.useCallback(() => {
    invoke(...args)
  }, [invoke, args])
  const setData = React.useCallback(
    (data: any) => {
      dispatch({ type: "SET_DATA", data })
    },
    [dispatch]
  )
  const prevIntervalActive = usePrevious(intervalActive)
  React.useEffect(() => {
    if (
      interval > 0 &&
      !prevIntervalActive &&
      intervalActive &&
      !isFetching &&
      called &&
      shouldCallIntervalRef.current?.()
    ) {
      // This effect is for when the interval was turned off then got turned back on
      invoke()
    }
  }, [interval, intervalActive, invoke, prevIntervalActive, called, isFetching])
  React.useEffect(() => {
    if (
      ((!lazy && interval === 0) ||
        (interval > 0 && intervalActive && !lazy)) &&
      !isFetching &&
      !called &&
      shouldCallIntervalRef.current?.()
    ) {
      // This effect is only for running on mount or for when the interval just got turned on
      // for the first time
      invoke()
    }
  }, [lazy, invoke, isFetching, called, interval, intervalActive])

  useInterval(invoke, interval, {
    shouldCallInterval,
    active: interval > 0 && intervalActive,
  })
  const state = useSelector(returnState ? (state) => state : () => {})
  let modal = null
  if (modals && modalKey) {
    let modalConfig = modals[modalKey]
    if (!modalConfig && modals.fallback) {
      modalConfig = modals.fallback
    }
    if (typeof modalConfig === "function") {
      modal = <AlertModal {...modalConfig()} />
    } else if (React.isValidElement<unknown>(modalConfig)) {
      modal = modalConfig
    } else if (typeof modalConfig === "object") {
      modal = <AlertModal {...modalConfig} />
    }
  }
  return {
    modal,
    setModalKey: actions.setModalKey,
    set,
    setters,
    actions,
    useSelector,
    dispatch,
    ready: (!isFetching && called) as boolean,
    retry,
    state: state as State & UseApiState<Data>,
    setData,
    data: state?.data as Data,
    errors,
    invoke: invoke as (...args: Parameters<ApiCall>) => Promise<Data>,
    isFetching,
    called,
    validationErrors,
    getState,
  }
}

export default useApi
