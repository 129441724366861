const tryParseLocale = (locale: string) => {
  try {
    return new Intl.Locale(locale)
  } catch {
    return null
  }
}

/**
 * These bcp47 regions should default to the traditional chinese font face.
 *
 * HK = Hong Kong
 * TW = Taiwan
 */
const traditionalChineseCharRegions = ["HK", "TW"]

/**
 * List of supported scripts in the format defined by [ISO_15924](https://en.wikipedia.org/wiki/ISO_15924).
 *
 * These can be found in bcp47 tags (optionally):
 *
 * ```js
 * console.log(new Intl.Locale("en-US").maximize().script)
 * // prints 'Latn'
 * ```
 */
const supportedScripts = [
  // traditional han script
  "Hant",
  // simplified han script
  "Hans",
  // japanese
  "Jpan",
  // korean
  "Kore",
  // thai
  "Thai",
  // tamil script
  "Taml",
  // telugu script
  "Telu",
  // gurmukhi script
  "Guru",
  // oriya (odia) script
  "Orya",
  // devanagari (nagari) script
  "Deva",
  // malayalam script
  "Mlym",
  // kannada script
  "Knda",
  // gujarati script
  "Gujr",
  // bengali (bangla) script
  "Beng",
  // arabic script
  "Arab",
  // hebrew script
  "Hebr",
  // arabic (Nastaliq variant) script
  "Aran",
  // latin script
  "Latn",
] as const
const supportedScriptsSet: ReadonlySet<string> = new Set<string>(
  supportedScripts
)

export type SupportedLocaleScript = (typeof supportedScripts)[number]

export const getLocaleScript = (localeTag: string): SupportedLocaleScript => {
  const locale = tryParseLocale(localeTag)
  if (locale?.script && supportedScriptsSet.has(locale.script)) {
    return locale.script as SupportedLocaleScript
  }
  switch (locale?.language) {
    case "zh":
      if (
        locale.region &&
        traditionalChineseCharRegions.includes(locale.region)
      ) {
        return "Hant"
      }
      return "Hans"
    case "ja":
      return "Jpan"
    case "ko":
      return "Kore"
    case "th":
      return "Thai"
    case "ar":
      return "Arab"
    case "he":
      return "Hebr"
    case "ur":
      return "Aran"
    case "gu":
      return "Gujr"
    case "pa":
      return "Guru"
    case "or":
      return "Orya"
    case "mr":
    case "hi":
      return "Deva"
    case "ml":
      return "Mlym"
    case "kn":
      return "Knda"
    case "ta":
      return "Taml"
    case "te":
      return "Telu"
    case "bn": /* falls-through */
    case "as":
      return "Beng"
    default:
      return "Latn"
  }
}

export type LocaleScript = ReturnType<typeof getLocaleScript>
