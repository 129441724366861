import React from "react"
import MessageDialog from "@myvp/shared/src/components/message-dialog"
import { useIntl } from "react-intl"

const SomethingWentWrongModal = (props: {
  onClose: () => void
  visible: boolean
}) => {
  const { formatMessage } = useIntl()

  if (!props.visible) {
    return null
  }

  return (
    <MessageDialog
      variant="alert"
      title={formatMessage({
        id: "register.somethingWentWrongTitle",
      })}
      message={formatMessage({
        id: "register.somethingWentWrongDescription",
      })}
      onConfirmText={formatMessage({ id: "alertModal.ok" })}
      onConfirm={props.onClose}
      onCloseText={undefined}
    />
  )
}

export default SomethingWentWrongModal
