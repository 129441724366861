import React from "react"
import { type IconProps, iconSizeCss } from "src/styles/icon-utils"
import { css, type Theme } from "@emotion/react"

const Alert = (props: {
  size?: IconProps["size"]
  variant?: "default" | "filled" | "less-intense"
}) => {
  const size = props.size ?? "small"
  const variant = props.variant ?? "default"
  let style = (theme: Theme) => css`
    --circleStroke: ${theme.palette.foreground.critical};
    --pathStroke: ${theme.palette.foreground.critical};
  `
  if (variant === "filled") {
    style = (theme: Theme) => css`
      --circleFill: ${theme.palette.foreground.critical};
      --pathStroke: ${theme.palette.foreground.inverse};
    `
  }
  if (variant === "less-intense") {
    style = (theme: Theme) => css`
      --circleFill: ${theme.palette.decorative.surface.red};
      --pathStroke: ${theme.palette.foreground.critical};
    `
  }
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={[iconSizeCss(size), style]}
    >
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke="var(--circleStroke)"
        fill="var(--circleFill)"
        strokeWidth="2"
      />
      <path
        d="M12 7V13.6667M12 15.3333V17"
        stroke="var(--pathStroke)"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Alert
