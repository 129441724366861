import type { GraphQLClient } from "graphql-request"
import {
  prepareHttpRequestHeaders,
  processHttpResponseHeaders,
} from "src/functions/app-http-headers"

export type RequestConfig = NonNullable<
  ConstructorParameters<typeof GraphQLClient>[1]
>
export type RequestMiddleware = NonNullable<RequestConfig["requestMiddleware"]>
type ResponseMiddleware = NonNullable<RequestConfig["responseMiddleware"]>

const isNestedErrorResponseHeaders = (
  response: object
): response is { response: Response } => {
  return Boolean(
    "response" in response &&
      response.response &&
      typeof response.response === "object" &&
      "headers" in response.response
  )
}

export const graphqlProcessRequestError = ((request) => {
  if (process.env.NODE_ENV === "test") {
    let operationName = ""
    try {
      operationName = JSON.parse(request.body as string).operationName
    } catch {}
    // eslint-disable-next-line no-console
    console.error(
      `You failed to mock a graphql request inside of a unit test: \n
       api: ${operationName}\n
       test: ${expect.getState().currentTestName}`
    )
  }

  return request
}) satisfies RequestMiddleware

export const graphqlAddRequestHeaders = ((request) => {
  return {
    ...request,
    headers: {
      // Custom headers specifically passed in an individual request are automatically added into request.headers
      ...request.headers,
      ...(prepareHttpRequestHeaders("http") as RequestInit["headers"]),
    },
  }
}) as RequestMiddleware

export const graphqlProcessResponseHeaders = ((response) => {
  let headers: Headers | undefined = undefined
  if ("headers" in response) {
    headers = response.headers as Headers
  } else if (isNestedErrorResponseHeaders(response)) {
    // When BE returns errors --> the response object comes back nested inside response
    headers = response.response.headers
  }

  if (headers) {
    processHttpResponseHeaders(headers, "http")
  }
}) satisfies ResponseMiddleware
