import React from "react"
import { type Theme, css, type Interpolation } from "@emotion/react"
import { FloatingPortalRoot } from "src/components/floating/portal"
import useMergeRefs from "src/hooks/use-merge-refs"
import { KEYS } from "src/constants"
import useRefCallback from "src/hooks/use-ref-callback"

const overflowCss = css`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  overflow: auto;
  background: transparent;
  ::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
`
const gridCss = css`
  display: inline-grid;
  padding: 24px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  place-items: center;
  min-width: 100%;
  min-height: 100%;
`
const baseDialogCss = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${theme.palette.border.default};
  background: ${theme.palette.surface.default};
  box-shadow: 0px 12px 48px 0px rgba(0, 0, 0, 0.15);
  color: ${theme.palette.foreground.default};
`
const floatingRootCss = css`
  position: absolute;
  z-index: 1000;
`

const BaseDialog = React.forwardRef<
  HTMLDialogElement,
  React.ComponentProps<"dialog"> & {
    onOpen?: () => void
    visible: boolean
    dialogCss?: Interpolation<Theme>
    ignoreEscapeKey?: boolean
  }
>(function BaseDialog(allProps, ref) {
  const { dialogCss, visible, ignoreEscapeKey, onOpen, ...props } = allProps

  const refDialog = React.useRef<HTMLDialogElement>(null)
  const combinedRefs = useMergeRefs([refDialog, ref])

  React.useEffect(() => {
    if (!visible || !ignoreEscapeKey) {
      return
    }
    const abortController = new AbortController()
    // NOTE: event listener needs to be on document or window (or body?) to work on safari
    document.addEventListener(
      "keydown",
      (event) => {
        if (event.key === KEYS.escape) {
          event.preventDefault()
        }
      },
      {
        // capture phase is needed to work correctly with select (`useDismiss` from floating ui probably stops propagation)
        capture: true,
        signal: abortController.signal,
      }
    )
    return () => {
      abortController.abort()
    }
  }, [visible, ignoreEscapeKey])

  const refOnOpen = useRefCallback(onOpen)

  const refRestoreOverflow = React.useRef("")
  React.useEffect(() => {
    const dialog = refDialog.current
    if (!dialog || visible === dialog.open) return

    if (visible) {
      refRestoreOverflow.current = document.body.style.overflow
      document.body.style.overflow = "hidden"
      dialog.showModal()
      refOnOpen.current?.()
    } else {
      dialog.close()
    }
  }, [visible])

  return (
    <dialog
      ref={combinedRefs}
      {...props}
      onClose={(event) => {
        document.body.style.overflow = refRestoreOverflow.current
        refRestoreOverflow.current = ""
        props.onClose?.(event)
      }}
      css={overflowCss}
    >
      <FloatingPortalRoot css={floatingRootCss}>
        <div css={gridCss}>
          <div css={[baseDialogCss, dialogCss]}>{allProps.children}</div>
        </div>
      </FloatingPortalRoot>
    </dialog>
  )
})

export default BaseDialog
