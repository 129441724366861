import React from "react"
import { I18nStateProvider, useI18nState } from "src/i18n/i18n-state-provider"
import { ThemeProvider, type Theme } from "@emotion/react"
import { theme as defaultTheme } from "src/styles/theme"
import { useQuery } from "@tanstack/react-query"
import { getLocaleScript } from "src/i18n/get-locale-script"
import { AlertList } from "src/components/alert-list"
import { getDateFnsLanguageLocaleFile } from "src/i18n/locale-helpers"
import defaultDateFnsLocale from "date-fns/locale/en-US"

export interface UIStateProvidersProps {
  locale: string
  updateIntl: (locale?: string) => void
  messages: Record<string, string>
  dateFnsLocale: import("date-fns").Locale
  rtl: boolean
  theme?: Theme
  children?: React.ReactNode
}

/**
 * React context providers for shared components to render with the same:
 *
 * - locale
 * - text direction
 *
 * Can be used directly to set these for a part of the UI.
 */
export const UIStateProviders = (props: UIStateProvidersProps) => {
  const theme = React.useMemo(() => {
    const baseTheme = props.theme ?? defaultTheme
    const localeScript = getLocaleScript(props.locale)
    return { ...baseTheme, isRTL: props.rtl, localeScript }
  }, [props.theme, props.locale, props.rtl])

  return (
    <I18nStateProvider
      locale={props.locale}
      dateFnsLocale={props.dateFnsLocale}
      updateIntl={props.updateIntl}
      messages={props.messages}
      rtl={props.rtl}
    >
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </I18nStateProvider>
  )
}

export const RootUIStateProviders = (props: UIStateProvidersProps) => {
  return (
    <UIStateProviders {...props}>
      <SetRootProperties />
      <AlertList>{props.children}</AlertList>
    </UIStateProviders>
  )
}

export interface UIProvidersProps {
  resolveLocale: (localeOverride?: string) => string
  loadMessages: (locale: string) => Promise<Record<string, string>>
  textDirection?: (locale: string) => "ltr" | "rtl"
  theme?: Theme
  staticTestMessages?: Record<string, string>
  children?: React.ReactNode
  queryKey?: string
}

export const UIProviders = (props: UIProvidersProps) => {
  const [locale, setLocale] = React.useState(props.resolveLocale)

  // NOTE: {stale,cache}Time aren't needed, `import` caches those requests
  const { data } = useQuery({
    notifyOnChangeProps: ["data"],
    queryKey: ["@myvp/shared", "i18n-provider", locale, props.queryKey],
    queryFn: async () => {
      const [messages, dateFnsLocale] = await Promise.all([
        props.loadMessages(locale),
        getDateFnsLanguageLocaleFile(locale),
      ])
      return { messages, dateFnsLocale: dateFnsLocale ?? defaultDateFnsLocale }
    },
    enabled: !props.staticTestMessages,
    // keep the previously loaded messages while the new locale loads.
    placeholderData: (previousData) => previousData,
  })

  const updateIntl = React.useCallback(
    (localeOverride?: string) => {
      const getLocale = props.resolveLocale
      const locale = getLocale(localeOverride)
      setLocale(locale)
    },
    [props.resolveLocale]
  )

  const messages = props.staticTestMessages ?? data?.messages
  const dateFnsLocale = props.staticTestMessages
    ? defaultDateFnsLocale
    : data?.dateFnsLocale
  return !messages || !dateFnsLocale ? null : (
    <UIStateProviders
      messages={messages}
      dateFnsLocale={dateFnsLocale}
      locale={locale}
      updateIntl={updateIntl}
      rtl={props.textDirection?.(locale) === "rtl"}
      theme={props.theme}
    >
      {props.children}
    </UIStateProviders>
  )
}

/**
 * Includes providers for an application and makes global modification to affect the
 * entire document.
 *
 * @see UIProviders
 */
export const RootUIProviders = (props: UIProvidersProps) => {
  return (
    <UIProviders {...props}>
      <SetRootProperties />
      <AlertList>{props.children}</AlertList>
    </UIProviders>
  )
}
const SetRootProperties = (): null => {
  const { rtl, locale } = useI18nState()
  React.useLayoutEffect(() => {
    document.documentElement.dir = rtl ? "rtl" : "ltr"
    document.documentElement.setAttribute(
      "data-font-face",
      getLocaleScript(locale) ?? ""
    )
  }, [rtl, locale])

  return null
}

export const LocaleRegion = (props: React.ComponentProps<"div">) => {
  const { locale, rtl } = useI18nState()

  return (
    <div
      {...props}
      className={["locale-region", props.className].join(" ")}
      dir={rtl ? "rtl" : "ltr"}
      lang={locale}
      data-font-face={getLocaleScript(locale)}
    >
      {props.children}
    </div>
  )
}
