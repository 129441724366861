import React from "react"
import useRefCallback from "src/hooks/use-ref-callback"

const noop = () => ({})
const useResizeObserver = <T extends Element>({
  callback,
  active = true,
  preserveOnDisappear = false,
  syncState = true,
}: {
  callback?: (entry: ResizeObserverEntry) => void
  active?: boolean
  preserveOnDisappear?: boolean
  syncState?: boolean
} = {}) => {
  const refCallback = useRefCallback(callback || noop)
  const [results, setResults] = React.useState({
    width: 0,
    height: 0,
    scrollWidth: 0,
    scrollHeight: 0,
    clientHeight: 0,
    clientWidth: 0,
  })
  const ref = React.useRef<T>(null)
  React.useEffect(() => {
    if (active && ref.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        const { target } = entries[0]
        const htmlTarget = target as HTMLElement
        refCallback.current?.(entries[0])
        if (syncState) {
          setResults((current) => {
            if (
              preserveOnDisappear &&
              current.width !== 0 &&
              current.height !== 0 &&
              htmlTarget.offsetWidth === 0 &&
              htmlTarget.offsetHeight === 0
            ) {
              // Item just disappeared, just set to current.
              return current
            }
            return {
              width: htmlTarget.offsetWidth,
              scrollWidth: htmlTarget.scrollWidth,
              height: htmlTarget.offsetHeight,
              scrollHeight: htmlTarget.scrollHeight,
              clientHeight: htmlTarget.clientHeight,
              clientWidth: htmlTarget.clientWidth,
            }
          })
        }
      })
      resizeObserver.observe(ref.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [active, preserveOnDisappear, setResults, syncState])
  return {
    width: results.width,
    height: results.height,
    scrollWidth: results.scrollWidth,
    scrollHeight: results.scrollHeight,
    clientHeight: results.clientHeight,
    clientWidth: results.clientWidth,
    ref,
  }
}

export default useResizeObserver

export const useOnResize = <T extends Element>(args: {
  onResize: (entry: ResizeObserverEntry) => void
  active?: boolean
}) => {
  const active = args.active ?? true

  const refCallback = React.useRef<typeof args.onResize | null>(null)
  React.useLayoutEffect(() => {
    refCallback.current = args.onResize
  })

  const resizeObserverRef = React.useRef<ResizeObserver | null>(null)

  return React.useCallback(
    (element: T | null) => {
      // cleanup previous resize observer
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect()
        resizeObserverRef.current = null
      }
      if (active && element) {
        resizeObserverRef.current = new ResizeObserver((entries) => {
          refCallback.current?.(entries[0])
        })
        resizeObserverRef.current.observe(element)
      }
    },
    [active]
  )
}
