import { resolveSupportedLocale, SUPPORTED_LOCALES } from "src/i18n/locales"
import getBrowserLocale from "@myvp/shared/src/i18n/get-browser-locale"
import { type GetUserMetadataQuery } from "src/gql/graphql"

export const getLocale = (localeOverride?: string) => {
  const userLocale = getCurrentLocale(localeOverride)
  return resolveSupportedLocale(userLocale) ?? SUPPORTED_LOCALES.enUS
}

const getCurrentLocale = (localeOverride?: string) => {
  // check query param locale value first
  const searchParams = new URLSearchParams(window.location.search)
  {
    const queryParamLocale = searchParams.get("locale")
    if (queryParamLocale) {
      return queryParamLocale as string
    }
  }
  if (localeOverride) {
    return localeOverride
  }
  // read from metadata storage and use `studyId` to try to find the current study language
  {
    const relatedUserId = searchParams.get("relatedUserId")
    const role = searchParams.get("role")
    const studyId = searchParams.get("studyId")
    let languageTag: string | undefined
    let metadata: GetUserMetadataQuery | null
    try {
      metadata = JSON.parse(sessionStorage.getItem("metadata") as string)
    } catch {
      metadata = null
    }
    try {
      const currentStudy = metadata?.userStudies?.userStudyRoles?.find(
        (userStudy) =>
          userStudy.studyDetails.studyId === studyId &&
          userStudy.role === role &&
          userStudy.participantDetails.relatedUserId === relatedUserId
      )
      languageTag = currentStudy?.languageTag
    } catch {
      languageTag = undefined
    }
    if (!languageTag) {
      try {
        const defaultStudy = metadata?.userStudies?.userStudyRoles?.find(
          (userStudy) => userStudy.isDefaultStudy
        )
        languageTag = defaultStudy?.languageTag
      } catch {
        languageTag = undefined
      }
    }
    if (languageTag) {
      return languageTag
    }
  }
  // read from session storage for locale used during registration
  try {
    const registrationLocale = sessionStorage.getItem("registrationLocale")
    if (typeof registrationLocale === "string") {
      return registrationLocale
    }
  } catch {}
  // use the browser's locale
  return getBrowserLocale()
}
