import React from "react"
import styled from "@emotion/styled"
import { css, useTheme } from "@emotion/react"

type Variant =
  | "xxlarge"
  | "xlarge"
  | "large"
  | "medium"
  | "small"
  | "xsmall-outlined"
  | "outlined"
  | "bold"
  | "scoring"
  | "medium-outlined"
const Svg = styled.svg<{
  variant: Variant
}>`
  min-width: 13px;
  width: 13px;
  height: 13px;
  min-height: 13px;
  ${(props) =>
    props.variant === "xxlarge" &&
    css`
      min-width: 56px;
      width: 56px;
      min-height: 56px;
      height: 56px;
    `}
  ${(props) =>
    props.variant === "xlarge" &&
    css`
      min-width: 32px;
      width: 32px;
      min-height: 32px;
      height: 32px;
    `}
  ${(props) =>
    props.variant === "large" &&
    css`
      min-width: 26px;
      width: 26px;
      min-height: 26px;
      height: 26px;
    `}
  ${(props) =>
    (props.variant === "medium" || props.variant === "medium-outlined") &&
    css`
      min-width: 24px;
      width: 24px;
      min-height: 24px;
      height: 24px;
    `}
  ${(props) =>
    props.variant === "outlined" &&
    css`
      min-width: 20px;
      width: 20px;
      min-height: 20px;
      height: 20px;
    `}
  ${(props) =>
    props.variant === "xsmall-outlined" &&
    css`
      min-width: 12px;
      width: 12px;
      min-height: 12px;
      height: 12px;
    `}
  ${(props) =>
    props.variant === "bold" &&
    css`
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;

      path {
        :first-of-type {
          fill: ${props.theme.palette.red.dark.zero};
        }
        :last-of-type {
          fill: ${props.theme.palette.steel.zero};
        }
      }
    `}
`
interface ViewBoxMap {
  outlined?: string
  "xsmall-outlined"?: string
  bold?: string
  xxlarge: string
}
const viewBoxMap: ViewBoxMap = {
  outlined: "0 0 20 20",
  "xsmall-outlined": "0 0 20 20",
  bold: "0 0 16 16",
  xxlarge: "0 0 56 56",
}

/** @deprecated */
const Alert = (props: { variant?: Variant; className?: string }) => {
  const theme = useTheme()
  const variant = props.variant ?? "small"
  const getSvgContent = () => {
    if (variant === "outlined" || variant === "xsmall-outlined") {
      return (
        <>
          <circle
            cx="10"
            cy="10"
            r="9"
            stroke={theme.palette.red.dark.zero}
            strokeWidth={2}
          />
          <path
            d="M10 4V12M10 14V16"
            stroke={theme.palette.red.dark.zero}
            strokeWidth={2}
          />
        </>
      )
    } else if (variant === "bold") {
      return (
        <>
          <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 9V3H9V9H7ZM9 11V13H7V11H9Z"
          />
        </>
      )
    } else if (variant === "medium") {
      return (
        <>
          <circle
            cx="12"
            cy="12"
            r="10"
            fill={theme.palette.foreground.critical}
          />
          <path
            d="M12 6V14M12 16V18"
            stroke={theme.palette.foreground.inverse}
            strokeWidth="2"
          />
        </>
      )
    } else if (variant === "medium-outlined") {
      return (
        <>
          <circle
            cx="12.5"
            cy="12"
            r="9"
            stroke={theme.palette.foreground.critical}
            strokeWidth="2"
          />
          <path
            d="M12.5 7V13.6667M12.5 15.3333V17"
            stroke={theme.palette.foreground.critical}
            strokeWidth="2"
          />
        </>
      )
    } else if (variant === "xxlarge") {
      return (
        <>
          <circle
            cx="28"
            cy="28"
            r="22.1875"
            fill={theme.palette.foreground.critical}
            stroke={theme.palette.foreground.critical}
            strokeWidth="3.625"
          />
          <path
            d="M28 14L28 32.5M28 37.5L28 42.5"
            stroke={theme.palette.surface.default}
            strokeWidth="3"
          />
        </>
      )
    } else {
      return (
        <>
          <circle cx="12" cy="12" r="12" fill={theme.palette.red.dark.zero} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 6H11V14H13V6ZM13 16H11V18H13V16Z"
            fill={theme.palette.steel.zero}
          />
        </>
      )
    }
  }
  return (
    <Svg
      focusable="false"
      variant={variant}
      className={props.className}
      viewBox={viewBoxMap[variant as keyof typeof viewBoxMap] ?? "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {getSvgContent()}
    </Svg>
  )
}

export default Alert
