import React from "react"
import { HttpCode } from "@myvp/shared/src/types"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { routeNames } from "src/router/route-names"
import { useErrorBoundary } from "@myvp/shared/src/hooks/use-error-boundary"
import MessageDialog from "@myvp/shared/src/components/message-dialog"

const ForgotPasswordErrorBoundary = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const errorConfigs = {
    [HttpCode.Unauthorized]: {
      title: formatMessage({ id: "error.sessionExpired" }),
      message: formatMessage({ id: "error.sessionExpiredMessage" }),
      onConfirmText: formatMessage({ id: "alertModal.ok" }),
      onConfirm: () =>
        navigate({
          pathname: routeNames.login,
          search: location.search,
        }),
    },
    fallback: {
      title: formatMessage({ id: "register.somethingWentWrongTitle" }),
      message: formatMessage({ id: "register.somethingWentWrongDescription" }),
      onConfirmText: formatMessage({ id: "alertModal.ok" }),
      onConfirm: () => window.location.reload(),
    },
  }

  const errorConfig = useErrorBoundary(errorConfigs)
  return (
    <MessageDialog
      variant={
        errorConfig.variant === "error"
          ? "alert"
          : errorConfig.variant ?? "alert"
      }
      title={errorConfig.title}
      message={errorConfig.message}
      onConfirm={errorConfig.onConfirm}
      onConfirmText={errorConfig.onConfirmText}
      onClose={errorConfig.onClose}
      onCloseText={errorConfig.onCloseText}
    />
  )
}

export default ForgotPasswordErrorBoundary
