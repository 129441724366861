import { graphql } from "src/gql"

export const ECONSENT_MFA = "ECONSENT_MFA"

export const IN_PERSON_PARAM = "inPersonEConsent"

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const WORD_DOCUMENT_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"

// If editing role names, update the eConsent preview invalidSignatoryRoleMessage
export const ROLE_NAMES = Object.freeze({
  participant: "PARTICIPANT",
  site: "SITE",
  witness: "WITNESS",
  secondWitness: "SECOND_WITNESS",
  translator: "TRANSLATOR",
  larGuardian: "LAR_GUARDIAN",
  secondLarGuardian: "SECOND_LAR_GUARDIAN",
  caregiver: "CAREGIVER",
  secondCaregiver: "SECOND_CAREGIVER",
  proxy: "PROXY",
})

type ValueOf<T> = T[keyof T]
type Role = ValueOf<typeof ROLE_NAMES>
const roleMap: Record<Role, { role: Role; signatoryRoleOrdinal: 1 | 2 }> = {
  [ROLE_NAMES.participant]: {
    role: ROLE_NAMES.participant,
    signatoryRoleOrdinal: 1,
  },
  [ROLE_NAMES.site]: {
    role: ROLE_NAMES.site,
    signatoryRoleOrdinal: 1,
  },
  [ROLE_NAMES.witness]: {
    role: ROLE_NAMES.witness,
    signatoryRoleOrdinal: 1,
  },
  [ROLE_NAMES.secondWitness]: {
    role: ROLE_NAMES.witness,
    signatoryRoleOrdinal: 2,
  },
  [ROLE_NAMES.translator]: {
    role: ROLE_NAMES.translator,
    signatoryRoleOrdinal: 1,
  },
  [ROLE_NAMES.larGuardian]: {
    role: ROLE_NAMES.larGuardian,
    signatoryRoleOrdinal: 1,
  },
  [ROLE_NAMES.secondLarGuardian]: {
    role: ROLE_NAMES.larGuardian,
    signatoryRoleOrdinal: 2,
  },
  [ROLE_NAMES.caregiver]: {
    role: ROLE_NAMES.caregiver,
    signatoryRoleOrdinal: 1,
  },
  [ROLE_NAMES.secondCaregiver]: {
    role: ROLE_NAMES.caregiver,
    signatoryRoleOrdinal: 2,
  },
  [ROLE_NAMES.proxy]: {
    role: ROLE_NAMES.proxy,
    signatoryRoleOrdinal: 1,
  },
}

export const getSignatoryRoleByName = (role: Role) => {
  return roleMap[role]
}

export const getSignatoryRoleNameByValue = (
  role: Role,
  signatoryRoleOrdinal: number
) => {
  return Object.entries(roleMap).find(
    (value) =>
      value[1].role === role &&
      value[1].signatoryRoleOrdinal === signatoryRoleOrdinal
  )?.[0]
}

export const QUESTION_ROLE_NAMES = Object.freeze({
  defaultRole: "DEFAULT",
  participant: "PARTICIPANT",
  witness: "WITNESS",
  secondWitness: "SECOND_WITNESS",
  translator: "TRANSLATOR",
  larGuardian: "LAR_GUARDIAN",
  secondLarGuardian: "SECOND_LAR_GUARDIAN",
  caregiver: "CAREGIVER",
  secondCaregiver: "SECOND_CAREGIVER",
  proxy: "PROXY",
})

export const CONFIRM_FIELD_TYPES = {
  email: "EMAIL",
  phone: "PHONE",
}

export const CODE_INPUT_ANIMATION_TIME = 1800

export const NOTIFICATION_APPLICATIONS = {
  ePro: "ePRO",
  eConsent: "eConsent",
  otherVisit: "other_visit",
  sharedDocs: "shared_documents",
  virtualVisit: "virtual_visit",
}

export const subTaskQuery = graphql(/* GraphQL*/ `
  fragment SubTaskQuery on UserSubTask {
    __typename
    id
    action
    actionDate
    displayName
    dueDateTime
    isViewable
    sequence
    startDateTime
    ... on SignEConsentSubTask {
      isUnlockCodeRequired
    }
  }
`)

export const userTaskQuery = graphql(/* GraphQL */ `
  fragment UserTaskQuery on UserTask {
    userRole {
      role
    }
    isComplete
    studyParticipant {
      lastName
      firstName
      globalParticipantId
      study {
        studyId
        studyName
      }
    }
  }
`)
