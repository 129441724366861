import { css } from "@emotion/react"
import styled from "@emotion/styled"
import React from "react"
import { Link } from "react-router-dom"
import {
  type CoreButtonProps,
  InternalButton,
  InternalLink,
  getButtonIconColor,
  heightStyles,
  variantStyles,
  activeCss,
} from "src/components/buttons/button-utils"
import { type IconColor, type IconElement } from "src/styles/icon-utils"
import { Theme } from "src/styles/theme"

interface IconButtonProps extends Omit<CoreButtonProps, "height"> {
  /**
   * this is a render prop
   *
   * you can either pass the icon component function
   * - e.g. `icon={Edit}`
   *
   * or you can pass an inline function that returns your icon
   * - e.g. `icon={() => <Notification />}`
   * - this is useful if you want to ignore the arguments passed to the render prop
   */
  icon: IconElement
  variant: "primary" | "secondary" | "tertiary"
  height: "xsmall" | "small" | "medium" | "large"
  iconColor?: IconColor // Only used for tertiary variant
  useAltHoverColor?: boolean
}
interface ExternalIconButtonProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "children">,
    IconButtonProps {
  internalType?: "button"
}
interface ExternalIconLinkButtonProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Link>, "children">,
    IconButtonProps {
  internalType: "link"
}

export const baseStyles = () => css`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  gap: 8px;
  cursor: pointer;
`
const widthStyles = {
  xsmall: css`
    width: 24px;
    min-width: 24px;
  `,
  small: css`
    width: 32px;
    min-width: 32px;
  `,
  medium: css`
    width: 40px;
    min-width: 40px;
  `,
  large: css`
    width: 44px;
    min-width: 44px;
  `,
}

const IconButton = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ExternalIconButtonProps | ExternalIconLinkButtonProps
>(function IconButton(props, ref) {
  const styles = [
    baseStyles(),
    widthStyles[props.height],
    heightStyles[props.height],
    variantStyles[props.variant],
    activeCss(!!props.isAnchorOpen),
    props.useAltHoverColor &&
      ((theme: Theme) => css`
        :hover {
          background-color: ${theme.palette.surface.selected.hovered};
        }
        :active {
          background-color: ${theme.palette.surface.selected.pressed};
        }
      `),
  ]
  const disabled =
    "disabled" in props
      ? props.disabled
      : props["aria-disabled"] === true || props["aria-disabled"] === "true"
  const internalChildren = props.icon({
    size: "medium",
    color: getButtonIconColor({
      variant: props.variant,
      disabled,
      iconColor: props.iconColor,
    }),
  })

  if (props.internalType === "link") {
    const {
      internalType,
      variant,
      height,
      icon,
      iconColor,
      isAnchorOpen,
      useAltHoverColor,
      ...linkProps
    } = props
    const linkRef = ref as React.ForwardedRef<HTMLAnchorElement>
    return (
      <InternalLink ref={linkRef} {...linkProps} css={styles}>
        {internalChildren}
      </InternalLink>
    )
  } else {
    const {
      internalType,
      variant,
      height,
      icon,
      iconColor,
      isAnchorOpen,
      useAltHoverColor,
      ...buttonProps
    } = props
    const buttonRef = ref as React.ForwardedRef<HTMLButtonElement>
    return (
      <InternalButton ref={buttonRef} {...buttonProps} css={styles}>
        {internalChildren}
      </InternalButton>
    )
  }
})

export default styled(IconButton)``
