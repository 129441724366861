import { graphqlClient } from "src/graphql-client"
import {
  consumeMockRestResponse,
  getMockData,
  handleGraphQLErrors,
  isMock,
} from "@myvp/shared/src/functions/mock"
import { httpRequest } from "src/functions/http-request"
import { getErrorCode } from "@myvp/shared/src/functions/get-error-code"
import { sleep, sleepIfNeeded } from "@myvp/shared/src/functions/sleep"
import { MfaType, SupportedLocale } from "src/types"
import { graphql } from "src/gql"
import {
  GetUserMetadataQuery,
  ValidateDobInput,
  ValidateDobMutation,
  ValidateNewActivationResponse,
} from "src/gql/graphql"
import { HttpCode } from "@myvp/shared/src/types"
import { UnregisteredAuthResponse } from "src/models/auth.model"
import { isStringEnumValue } from "@myvp/shared/src/functions/type-guards"
import { routeNames } from "src/router/route-names"

export const authRequest = async ({
  data = {},
  url,
  method = "POST",
  baseUrl = "",
  headerContentType = "application/json",
}: {
  data?: object
  url: string
  method?: string
  baseUrl?: string
  headerContentType?: "application/json" | "application/x-www-form-urlencoded"
}) => {
  let body =
    headerContentType === "application/json"
      ? JSON.stringify({ ...data, client: process.env.REACT_APP_auth_client })
      : new URLSearchParams({
          ...data,
          client: process.env.REACT_APP_auth_client as string,
        }).toString()

  try {
    const result = await httpRequest(`${baseUrl}/auth${url}`, {
      headers: {
        "Content-Type": headerContentType,
      },
      method,
      ...(method !== "GET" ? { body } : {}),
    })
    const contentType = result.headers.get("content-type")
    if (
      getErrorCode(result) !== HttpCode.NoContent &&
      contentType &&
      contentType.includes("application/json")
    ) {
      const json = await result.json()
      // Specifically _httpHeaders to avoid any possible name collisions
      json._httpHeaders = result.headers
      return json
    } else {
      return { status: getErrorCode(result) }
    }
  } catch (error) {
    const e = error as {
      headers: Headers
      errorJson: object
      json: () => Promise<object>
    }
    if (e?.headers?.get?.("content-type") === "application/json") {
      e.errorJson = await e.json()
    }
    throw e
  }
}

export const resendMfaCode = async (args: {
  username?: string
  email?: string | null
  mfaType?: MfaType
  locale: string
}) => {
  if (!isMock()) {
    if (args.mfaType === MfaType.Email) {
      return authRequest({
        url: "/send-email-verification",
        data: { loginMFA: true },
        headerContentType: "application/x-www-form-urlencoded",
      })
    } else {
      return authRequest({
        url: `/mfa/send`,
        data: {
          username: args.username,
          email: args.email ?? "",
          mfaType: args.mfaType ?? MfaType.Sms,
          locale: args.locale,
        },
        headerContentType: "application/x-www-form-urlencoded",
      })
    }
  }
  let start = Date.now()
  const { resendMfaCode } = await getMockData()
  await sleepIfNeeded(500, start)
  return consumeMockRestResponse(resendMfaCode)
}

export const login = async (input: {
  baseUrl: string
  username: string
  password: string
  notificationRequestId: string
  ref: string
}) => {
  if (!isMock()) {
    return authRequest({
      url: `/authorize-patient`,
      baseUrl: input.baseUrl,
      data: {
        username: input.username,
        password: input.password,
        type: process.env.REACT_APP_auth_type,
        // this indicates that we do not want to automatically send a request to Twilio
        mfaType: "none",
        ...(input.ref && input.notificationRequestId
          ? // only include the ref and id if both are present
            {
              notificationRequestId: input.notificationRequestId,
              ref: input.ref,
            }
          : {}),
      },
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    const { login } = await getMockData()
    return consumeMockRestResponse(login[input.username])
  }
}

export const inPersonLogin = async (
  authToken: string
): Promise<UnregisteredAuthResponse> => {
  if (!isMock()) {
    return authRequest({
      url: `/authorize-inperson-user`,
      data: {
        auth: authToken,
        type: process.env.REACT_APP_auth_type,
      },
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    const searchParams = new URLSearchParams(window.location.search)
    const notificationId = searchParams.get("notificationId")
    const { inPersonLogin } = await getMockData()
    consumeMockRestResponse(inPersonLogin[notificationId as string])
    return inPersonLogin[notificationId as string]
  }
}

export const reauth = async (input: {
  password: string
}): Promise<{ status: HttpCode; acctVerified: boolean }> => {
  if (!isMock()) {
    return authRequest({
      url: `/reauthorize-patient`,
      data: {
        password: input.password,
      },
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    if (input.password === "wrong") {
      return { status: HttpCode.Unauthorized, acctVerified: false }
    } else if (input.password === "error") {
      return { status: HttpCode.ServerError, acctVerified: false }
    }
    const { reauth } = await getMockData()
    consumeMockRestResponse(reauth)
    return reauth
  }
}

export const requestForgotPasswordCode = async (input: {
  username: string
  baseUrl?: string
}) => {
  if (!isMock()) {
    return authRequest({
      url: "/forgot-password",
      baseUrl: input.baseUrl,
      data: input,
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    return getMockData().then(({ requestForgotPasswordCode }) =>
      consumeMockRestResponse(requestForgotPasswordCode)
    )
  }
}

export const enterMfaCode = async (input: {
  code: string
  mfaType: MfaType
}) => {
  if (!isMock()) {
    return authRequest({
      url: `/verify-mfa`,
      data: input,
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    await sleep(500)
    return getMockData().then(({ enterMfaCode }) =>
      enterMfaCode ? consumeMockRestResponse(enterMfaCode) : {}
    )
  }
}

export const verifyMfaCode = (input: { code: string; mfaType: MfaType }) => {
  if (!isMock()) {
    return authRequest({
      data: input,
      url: "/mfa/verify",
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    return getMockData().then(({ verifyMfaCode }) =>
      verifyMfaCode ? consumeMockRestResponse(verifyMfaCode) : {}
    )
  }
}

export const logout = () => {
  if (!isMock()) {
    return authRequest({
      url: `/logout`,
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    return {}
  }
}

const storage = sessionStorage

const metadataKey = "metadata"

export const getUserInfo = async (): Promise<GetUserMetadataQuery> => {
  if (!isMock()) {
    const query = graphql(/* GraphQL */ `
      query getUserMetadata {
        userInfo: getUserInfo {
          firstName
          lastName
          email
          formattedPhoneNumber
          phoneNumber
          pendingEmail
          timeZoneId
          username
          newTermsVersion
        }
        userStudies: getStudies {
          userStudyRoles {
            role
            studyDetails {
              studyName
              studyId
            }
            participantDetails {
              firstName
              lastName
              relatedUserId
            }
            toDoCount
            isDefaultStudy
            languageTag
            timeZoneLabel
          }
        }
      }
    `)
    const result = await graphqlClient.request(query)
    return result
  } else {
    const { getUserInfo, fetchUserMetadata } = await getMockData()
    let result = getUserInfo ?? fetchUserMetadata
    const searchParams = new URLSearchParams(window.location.search)
    const locale = searchParams.get("locale")
    if (locale) {
      result.locale = locale
    }
    if (
      // so that we only get the modal on the todo page
      // (easier for app screenshot generation)
      result.userInfo.newTermsVersion &&
      window.location.pathname !== routeNames.todo
    ) {
      result.userInfo.newTermsVersion = null
    }
    return result
  }
}

export const getUserMetadata = async (): Promise<
  GetUserMetadataQuery | undefined
> => {
  if (process.env.NODE_ENV === "test") {
    return {
      userInfo: {
        firstName: "William",
        lastName: "Walstrop",
        formattedPhoneNumber: "1 342-444-1288",
        phoneNumber: "+13424441288",
        email: "william.walstrop@merck.com",
        timeZoneId: "America/New_York",
      },
      userStudies: {
        userStudyRoles: [],
      },
    }
  }
  if (isMock()) {
    return undefined
  }

  let result: GetUserMetadataQuery
  try {
    const item = storage.getItem(metadataKey)
    result = !item ? {} : JSON.parse(item)
  } catch {
    result = {} as GetUserMetadataQuery
  }

  return result
}

export const persistUserMetadata = (metadata: GetUserMetadataQuery) => {
  storage.setItem(metadataKey, JSON.stringify(metadata))
}

export const removeUserMetadata = () => {
  storage.removeItem(metadataKey)
}

/**
 * Called to validate that a code sent to a user's email is correct. This api does
 * not require a valid JWT, and so it is different from validateNewEmailCode.
 */
export const validateEmailCode = async ({
  code,
  username,
  newEmail = "",
  mfaCode = "",
}: {
  code: string
  username?: string
  newEmail?: string
  mfaCode?: string
}) => {
  if (!isMock()) {
    return authRequest({
      data: username ? { code, username, newEmail, mfaCode } : { code },
      url: "/validate-email-code",
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    return getMockData().then(({ validateEmailCode }) =>
      consumeMockRestResponse(
        validateEmailCode[code] ?? { status: HttpCode.Ok }
      )
    )
  }
}

/**
 * Called when updating user's email address to mark the pending email as current.
 * Unlike validateEmailCode, this requires a verified JWT to be set and so must be called
 * after the user is logged in.
 */
export const validateNewEmailCode = async ({
  code,
  newEmail,
  mfaCode = "",
  mfaType,
}: {
  code?: string
  newEmail?: string
  mfaCode?: string
  mfaType?: MfaType
}) => {
  if (!isMock()) {
    return authRequest({
      data: { code, newEmail, mfaCode, mfaType },
      url: "/validate-new-email-code",
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    if (code === "500") {
      return consumeMockRestResponse({ status: HttpCode.ServerError })
    }
    return getMockData().then(({ validateNewEmailCode }) =>
      consumeMockRestResponse(validateNewEmailCode ?? { status: HttpCode.Ok })
    )
  }
}

/**
 * Called to determine a user's region, given a username. If the username doesn't exist,
 * the api will still return a successful response, but the userRegionAffinity will be null.
 */
export const userDiscovery = async ({
  username,
  baseUrl,
}: {
  username: string
  baseUrl?: string
}): Promise<{
  authId: string
  userRegionAffinity:
    | [
        {
          code: string
          dns: string
          createdAt: string
        },
      ]
    | null
  status: HttpCode
}> => {
  if (!isMock()) {
    return authRequest({
      url: `/user-discovery`,
      baseUrl,
      data: {
        username,
      },
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    const { userDiscovery } = await getMockData()
    consumeMockRestResponse(userDiscovery)
    return userDiscovery
  }
}

export const updatePhoneNumber = async (args: {
  updatedPhoneNumber: string
  locale: SupportedLocale
  mfaType?: MfaType
}) => {
  if (!isMock()) {
    return authRequest({
      data: {
        updatedPhoneNumber: args.updatedPhoneNumber,
        locale: args.locale,
        mfaType: args.mfaType,
      },
      url: "/mfa/update-phone-number",
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    await sleep(500)
    let status = HttpCode.Ok
    switch (args.updatedPhoneNumber) {
      case "+14009999999":
        status = HttpCode.BadRequest
        break
      case "+14019999999":
        status = HttpCode.Unauthorized
        break
      case "+14129999999":
        status = HttpCode.PreconditionFailed
        break
      case "+14299999999":
        status = HttpCode.TooManyRequests
        break
      case "+15009999999":
        status = HttpCode.ServerError
        break
      case "+14019999999":
        status = HttpCode.Unauthorized
        break
      default:
        status = HttpCode.Ok
    }
    const mockResponse = {
      status,
    }
    consumeMockRestResponse(mockResponse)
    return mockResponse
  }
}

export const verifyUpdatedPhoneNumber = async (args: {
  code: string
  updatedPhoneNumber: string
  mfaType: MfaType
  locale: string
}) => {
  if (!isMock()) {
    return authRequest({
      data: {
        code: args.code,
        updatedPhoneNumber: args.updatedPhoneNumber,
        mfaType: args.mfaType,
        locale: args.locale,
      },
      url: "/mfa/verify-updated-phone-number",
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    await sleep(500)
    let status = HttpCode.Ok
    const inputCode = args.code.slice(0, 3)
    if (
      inputCode === args.code.slice(3) &&
      isStringEnumValue(HttpCode)(inputCode)
    ) {
      status = inputCode
    }

    const mockResponse = {
      status,
    }
    consumeMockRestResponse(mockResponse)
    return mockResponse
  }
}

export const verifyEmailMfa = (
  token: string
): Promise<{ status?: string; _httpHeaders?: Headers }> => {
  if (!isMock()) {
    return authRequest({
      url: "/verify-email",
      data: { token, loginMFA: true },
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    const mockHeaders = new Headers()
    mockHeaders.set(
      "x-atkn",
      // jwt with ver: "true"
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZXIiOnRydWV9.AT1s6uJFE3X9P7Bq88ExsAQfPnwLkPg1Z7lihjHx0cc"
    )
    return Promise.resolve({
      status: "200",
      _httpHeaders: mockHeaders,
    })
  }
}

export const verifyEmail = async (token: string) => {
  if (!isMock()) {
    return authRequest({
      url: "/verify-email",
      data: { token },
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    const { verifyEmail } = await getMockData()
    return consumeMockRestResponse(verifyEmail)
  }
}

export const updateEmail = async ({
  newEmail,
  forceEmailSend = false,
  locale,
}: {
  newEmail: string
  forceEmailSend?: boolean
  locale: string
}): Promise<{ status: HttpCode }> => {
  if (!isMock()) {
    return authRequest({
      data: { newEmail, forceEmailSend, locale },
      url: "/userinfo/new-email",
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    if (newEmail === "500@500.com") {
      consumeMockRestResponse({ status: HttpCode.ServerError })
    }
    const { updateEmail } = await getMockData()
    consumeMockRestResponse(updateEmail)
    return updateEmail
  }
}

export const isValidVaultSession = async () => {
  if (!isMock()) {
    return authRequest({
      url: "/is-valid-vault-session",
      method: "GET",
      headerContentType: "application/x-www-form-urlencoded",
    })
  } else {
    const {
      isValidVaultSession = {
        status: HttpCode.Ok,
      },
    } = await getMockData()
    consumeMockRestResponse(isValidVaultSession)
    return isValidVaultSession
  }
}

export const setUsername = async (
  username: string
): Promise<{
  Status?: string
  status?: HttpCode
}> => {
  if (!isMock()) {
    return authRequest({
      url: "/set-username",
      data: { username },
    })
  } else {
    let status
    switch (username) {
      case "unauthorized":
        status = HttpCode.Unauthorized
        break
      case "conflict":
        status = HttpCode.Conflict
        break
      case "invalid":
        status = HttpCode.BadRequest
        break
      case "error":
        status = HttpCode.ServerError
        break
      default:
        consumeMockRestResponse({ Status: "OK" })
        return { Status: "OK" }
    }
    consumeMockRestResponse({ status })
    return { status }
  }
}

export const setPassword = async (
  password: string
): Promise<{
  status: HttpCode
  _httpHeaders?: Headers
  phoneCountryCode: string
  validatedPhoneNum: string
  email: string
}> => {
  if (!isMock()) {
    return authRequest({
      url: "/set-password",
      data: { password },
    })
  } else {
    let status = HttpCode.Ok
    if (password === "Test400!") {
      status = HttpCode.BadRequest
    }
    // Session needs to be invalidated for this to work
    if (password === "Test401!") {
      status = HttpCode.Unauthorized
    }
    if (password === "Test500!") {
      status = HttpCode.ServerError
    }
    const mockResponse = {
      status,
      phoneCountryCode: "+1",
      validatedPhoneNum: password === "noUserInfo1!" ? "" : "1234567890",
      email: password === "noUserInfo1!" ? "" : "testuser@veeva.com",
    }
    consumeMockRestResponse(mockResponse)
    return mockResponse
  }
}

export const setEmail = async (
  email: string
): Promise<{ status: HttpCode }> => {
  if (!isMock()) {
    return authRequest({
      url: "/set-email",
      data: { email },
    })
  } else {
    let status = HttpCode.Ok
    if (email === "error400@veeva.com") {
      status = HttpCode.BadRequest
    } else if (email === "error401@veeva.com") {
      status = HttpCode.Unauthorized
    } else if (email === "error500@veeva.com") {
      status = HttpCode.ServerError
    }
    const mockResponse = {
      status,
    }
    consumeMockRestResponse(mockResponse)
    return mockResponse
  }
}

export const validateDob = async (
  input: ValidateDobInput
): Promise<ValidateDobMutation["validateDob"]> => {
  if (!isMock()) {
    const mutation = graphql(/* GraphQL */ `
      mutation validateDob($input: ValidateDobInput!) {
        validateDob(input: $input) {
          success
        }
      }
    `)
    const result = await graphqlClient.request(mutation, {
      input: {
        dob: input.dob,
        relatedUserRoleId: input.relatedUserRoleId,
        authId: input.authId,
        activationCode: input.activationCode,
      },
    })
    return result.validateDob
  } else {
    // To show loading spinner
    await sleep(2000)
    switch (input.dob) {
      case "2000-04-01":
        handleGraphQLErrors({
          graphQLErrors: [{ extensions: { errorCode: HttpCode.BadRequest } }],
        })
      case "2000-04-02":
        handleGraphQLErrors({
          graphQLErrors: [{ extensions: { errorCode: HttpCode.Unauthorized } }],
        })
      case "2000-01-01":
        handleGraphQLErrors({
          graphQLErrors: [
            { extensions: { errorCode: HttpCode.PreconditionFailed } },
          ],
        })
      case "2000-04-23":
        handleGraphQLErrors({
          graphQLErrors: [{ extensions: { errorCode: HttpCode.Locked } }],
        })
      case "2000-05-01":
        handleGraphQLErrors({
          graphQLErrors: [{ extensions: { errorCode: HttpCode.ServerError } }],
        })
    }
    return {
      success: true,
    }
  }
}

// This is used during the forgot password flow to check MFA and the email code at once
export const authorizeMfaCodes = async (args: {
  username: string
  emailCode: string
  mfaCode: string
  mfaType?: string // BE defaults to SMS
}): Promise<{ status: HttpCode }> => {
  if (!isMock()) {
    return authRequest({
      url: "/authorize-mfa-codes",
      data: { ...args },
    })
  } else {
    let status = HttpCode.Ok
    const inputCode = args.mfaCode.slice(0, 3)
    if (
      inputCode === args.mfaCode.slice(3) &&
      isStringEnumValue(HttpCode)(inputCode)
    ) {
      status = inputCode
    }

    const mockResponse = {
      status,
    }
    consumeMockRestResponse(mockResponse)
    return mockResponse
  }
}

export const validateNewActivationCode = async (
  activationCode: string
): Promise<ValidateNewActivationResponse> => {
  if (!isMock()) {
    const mutation = graphql(/* GraphQL */ `
      mutation validateNewActivationCode($input: ValidateNewActivationInput!) {
        validateNewActivationCode(input: $input) {
          relatedUserId
          relatedUserRoleId
          authId
          dobRequired
        }
      }
    `)
    const result = await graphqlClient.request(mutation, {
      input: {
        activationCode,
      },
    })
    return result.validateNewActivationCode
  } else {
    const fakeUserResponse = {
      relatedUserId: "fake",
      relatedUserRoleId: "fake",
      dobRequired: false,
      authId: "fake",
    }
    if (activationCode === "ZZZZZZ") {
      handleGraphQLErrors({
        graphQLErrors: [{ extensions: { errorCode: HttpCode.BadRequest } }],
      })
      return fakeUserResponse
    } else if (activationCode === "XXXXXX") {
      handleGraphQLErrors({
        graphQLErrors: [{ extensions: { errorCode: HttpCode.ServerError } }],
      })
      return fakeUserResponse
    } else if (activationCode === "YYYYYY") {
      handleGraphQLErrors({
        graphQLErrors: [
          { extensions: { errorCode: HttpCode.PreconditionFailed } },
        ],
      })
      return fakeUserResponse
    } else if (activationCode === "UUUUUU") {
      // Can only happen in activate new study
      handleGraphQLErrors({
        graphQLErrors: [{ extensions: { errorCode: HttpCode.Unauthorized } }],
      })
      return fakeUserResponse
    } else if (activationCode === "DOBDOB") {
      return {
        relatedUserId: "578daf5a-0ea3-484e-88fa-f53db1ef2206",
        relatedUserRoleId: "27431890-7c05-4024-9ea8-ea1c6b4d7ad7",
        dobRequired: true,
        authId: "a240cc48-2da5-4e2a-b953-fc8e46ac3771",
      }
    } else if (activationCode === "PIIPII") {
      // Can only happen in activate new study
      handleGraphQLErrors({
        graphQLErrors: [{ extensions: { errorCode: HttpCode.Forbidden } }],
      })
      return fakeUserResponse
    } else {
      return {
        relatedUserId: "578daf5a-0ea3-484e-88fa-f53db1ef2206",
        relatedUserRoleId: "27431890-7c05-4024-9ea8-ea1c6b4d7ad7",
        dobRequired: false,
        authId: "a240cc48-2da5-4e2a-b953-fc8e46ac3771",
      }
    }
  }
}

export const confirmTerms = async (versionNumber: string) => {
  if (!isMock()) {
    return authRequest({
      url: "/confirm-terms",
      data: { newTermsVersion: versionNumber },
    })
  } else {
    let status
    switch (versionNumber) {
      case "":
        status = HttpCode.Unauthorized
        break
      case "invalid":
        status = HttpCode.BadRequest
        break
      default:
        consumeMockRestResponse({ Status: "OK" })
        return { Status: "OK" }
    }
    consumeMockRestResponse({ status })
    return { status }
  }
}
