import { getErrorCode } from "src/functions/get-error-code"
import { GraphQLError } from "graphql/error"
import { HttpCode } from "src/types"

export function isMock(): boolean {
  const searchParams = new URLSearchParams(window.location.search)
  const mock = searchParams.get("mock")
  const json = searchParams.get("json")

  return (
    (!!mock || !!json) &&
    (process.env.NODE_ENV === "development" ||
      !!process.env.REACT_APP_mock_services)
  )
}

export function handleGraphQLErrors(
  data:
    | {
        [key: string]:
          | {
              graphQLErrors?: GraphQLError[]
              errors?: [unknown]
            }
          | GraphQLError[]
          | object
      }
    | undefined
) {
  if (data === undefined) {
    return {}
  }

  if (Object.keys(data).length === 1 && data.graphQLErrors) {
    throw { graphQLErrors: data.graphQLErrors }
  }
  // @ts-expect-error
  if (Object.keys(data).length === 1 && data.response?.errors) {
    throw {
      response: data.response,
    }
  }
  return data ?? {}
}

export async function getMockData() {
  const searchParams = new URLSearchParams(window.location.search)
  const json = searchParams.get("json")
  if (!json) {
    return {}
  }
  if (json.endsWith(".ts")) {
    if (json.includes("src") || json.includes("/mocks")) {
      // eslint-disable-next-line no-alert
      window.alert(
        "When using a .ts file as your mock, do not include mocks or src. Specify the file from `src/mocks/`"
      )
    }
    try {
      const result = await import(`src/mocks/${json}`)
      return result.default
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      throw e
    }
  }
  const result = await fetch(json)
  return result.json()
}

export function consumeMockRestResponse(
  _response: {
    status?: string | number
    _httpHeaders?: Headers | null
    Status?: string
  } = {
    status: HttpCode.Ok,
    _httpHeaders: null,
  }
) {
  const response = { ..._response }
  const errorCode = getErrorCode(response)
  if (
    errorCode === HttpCode.Ok ||
    errorCode === HttpCode.NoContent ||
    !errorCode
  ) {
    response._httpHeaders = new Headers()
    return response
  } else {
    throw response
  }
}
