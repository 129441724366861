import React from "react"
import { css } from "@emotion/react"
import { Theme } from "src/styles/theme"

export type IconElement = (props: IconProps) => React.ReactElement

export const ICON_SIZES = ["small", "medium", "large"] as const
type IconSize = (typeof ICON_SIZES)[number]
export const ICON_COLORS = [
  "default",
  "alt",
  "inverse",
  "link",
  "critical",
  "disabled",
  "disabledAlt",
  "highlight",
  "success",
] as const
export type IconColor = (typeof ICON_COLORS)[number]

export type IconProps = React.SVGProps<SVGSVGElement> & {
  className?: string
  size?: IconSize
  color?: IconColor
}

export const getIconViewBox = (size?: IconSize) => {
  switch (size) {
    case "small":
      return "0 0 16 16"
    case "medium":
      return "0 0 24 24"
    case "large":
    default:
      return "0 0 32 32"
  }
}

export const iconSizeCss = (size?: IconSize) => {
  let pxSize
  switch (size) {
    case "small":
      pxSize = "16px"
      break
    case "medium":
      pxSize = "24px"
      break
    case "large":
    default:
      pxSize = "32px"
      break
  }

  return css`
    width: ${pxSize};
    min-width: ${pxSize};
    height: ${pxSize};
    min-height: ${pxSize};
  `
}

export const iconColorCss =
  (color?: IconColor, cssVarName = "color") =>
  (theme: Theme) => css`
      --${cssVarName}: ${theme.palette.foreground[color ?? "default"]};
    `
