import React from "react"
import useRefCallback from "src/hooks/use-ref-callback"

const useEventListener = <T extends Event>(
  type: string,
  callback: (event: T) => void,
  {
    rerender = false,
    active = true,
    object = window,
  }: {
    rerender?: boolean
    active?: boolean
    object?: Window | Document | HTMLElement
  } = {}
) => {
  const [, setRerender] = React.useState(0)
  const refCallback = useRefCallback(callback)
  const myCallback = React.useCallback(
    (e: Event) => {
      ;(refCallback as any).current(e)
      if (rerender) {
        setRerender((prev) => prev + 1)
      }
    },
    [setRerender, rerender]
  )
  React.useEffect(() => {
    if (active) {
      object.addEventListener(type, myCallback)
      return () => {
        object.removeEventListener(type, myCallback)
      }
    }
  }, [active, myCallback, object, type])
}

export default useEventListener
