import React from "react"
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { defaultReactQueryClientOptions } from "@myvp/shared/src/default-react-query-client-options"
import { createLoadLocale } from "@myvp/shared/src/i18n/load-locale"
import { RootUIStateProviders } from "@myvp/shared/src/ui-providers"
import { getLocale } from "src/i18n/get-locale"
import { getTextDirection } from "@myvp/shared/src/functions/get-text-direction"
import _enUS from "src/i18n/languages/enUS"
import eproViewerEnUs from "@myvp/epro-viewer/src/i18n/languages/enUS"
import defaultDateFnsLocale from "date-fns/locale/en-US"

const enUS = {
  ..._enUS,
  ...eproViewerEnUs,
}
const queryClient = new QueryClient({
  defaultOptions: defaultReactQueryClientOptions,
})

const Providers = (props: {
  children?: React.ReactNode
  queryClient?: QueryClient
  /**
   * This should only be provided in test cases.
   */
  staticTestMessages?: Record<string, string>
}) => {
  return (
    <QueryClientProvider client={props.queryClient ?? queryClient}>
      <RootUIProviders staticTestMessages={props.staticTestMessages}>
        {props.children}
      </RootUIProviders>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default Providers

const RootUIProviders = (props: {
  children?: React.ReactNode
  /**
   * This should only be provided in test cases.
   */
  staticTestMessages?: Record<string, string>
}) => {
  const [locale, setLocale] = React.useState(() => getLocale())

  // NOTE: {stale,cache}Time aren't needed, `import` caches those requests
  const { data } = useQuery({
    notifyOnChangeProps: ["data"],
    queryKey: ["@myvp/patients", "root-i18n-provider", locale],
    queryFn: async () => {
      return loadLocale(locale)
    },
    enabled: !props.staticTestMessages,
    placeholderData: keepPreviousData,
  })

  const updateIntl = React.useCallback((localeOverride?: string) => {
    const locale = getLocale(localeOverride)
    setLocale(locale)
  }, [])

  const messages = props.staticTestMessages ?? data?.messages
  const dateFnsLocale = props.staticTestMessages
    ? defaultDateFnsLocale
    : data?.dateFnsLocale
  return !messages || !dateFnsLocale ? null : (
    <RootUIStateProviders
      locale={locale}
      updateIntl={updateIntl}
      rtl={getTextDirection(locale) === "rtl"}
      messages={messages}
      dateFnsLocale={dateFnsLocale}
    >
      {props.children}
    </RootUIStateProviders>
  )
}

const loadLocale = createLoadLocale({
  loadMessages: [
    enUS,
    {
      name: "src/i18n/languages",
      load: (messagesKey) => import(`src/i18n/languages/${messagesKey}`),
    },
  ],
})
