import React from "react"
import useRefCallback from "src/hooks/use-ref-callback"

const useInterval = (
  callback: () => void,
  time: number,
  {
    shouldCallInterval = () => true,
    active = true,
    runImmediately = false,
  }: {
    shouldCallInterval?: () => boolean
    active?: boolean
    runImmediately?: boolean
  } = {}
) => {
  const refCallback = useRefCallback(callback)
  const shouldCallIntervalRef = useRefCallback(shouldCallInterval)

  React.useEffect(() => {
    let didFirstRun = false
    const handler = () => {
      if (shouldCallIntervalRef.current()) {
        refCallback.current()
      }
    }
    if (active) {
      if (runImmediately && !didFirstRun) {
        didFirstRun = true
        handler()
      }

      const id = setInterval(handler, time)
      return () => {
        return clearInterval(id)
      }
    }
  }, [time, active, runImmediately])
}

export default useInterval
