import React from "react"
import { sleep } from "src/functions/sleep"
import useRefCallback from "src/hooks/use-ref-callback"
import { CustomEvent } from "src/types"
import { isEmptyObject } from "src/functions/is-empty-object"
import { isDefined } from "src/functions/type-guards"

export interface FormState<ValueType> {
  values: ValueType
  errors: {
    validationErrors?: { [key in keyof ValueType]?: any }
    submitError?: string | React.ReactNode
  }
  isSubmitting: boolean
  animating: boolean
  hasChanges: boolean
  hasSubmitted: boolean
  blurred: { [key in keyof ValueType]?: boolean }
}

export type StopArgs<ValueType> = Partial<
  Pick<
    FormState<ValueType>,
    "errors" | "isSubmitting" | "hasChanges" | "blurred"
  >
>

type UseFormProps<ValueType> = {
  initialErrors?:
    | FormState<ValueType>["errors"]
    | ((initialValues: ValueType) => FormState<ValueType>["errors"])
  hasInitialChanges?: boolean
  submitListener?: (values: ValueType) => boolean
  isValidateValid?: (
    validationResult: FormState<ValueType>["errors"]
  ) => boolean
  onChange?: (values: ValueType) => void
  validateOnChange?: boolean
  validateOnBlur?: boolean
  animationTime?: number
  validate?: (
    values: ValueType,
    args: {
      type: "change" | "blur" | "submit"
      currentErrors: FormState<ValueType>["errors"]
      blurred: FormState<ValueType>["blurred"]
    }
  ) => FormState<ValueType>["errors"] | Promise<FormState<ValueType>["errors"]>
  onSubmit?: (
    values: ValueType,
    args: {
      setSubmitting: (submitting: boolean) => void
      setErrors: (errors: FormState<ValueType>["errors"]) => void
      stop: (args?: StopArgs<ValueType>) => void
      animate: () => Promise<void>
      metadata?: any
    }
  ) => void | Promise<void>
} & (
  | {
      storageKey?: undefined
      initialValues: ValueType | (() => ValueType)
      storage?: undefined
    }
  | {
      storageKey: string
      initialValues: ValueType | ((storedValues: ValueType | null) => ValueType)
      storage?: Storage
      clearStorageOnSubmit?: boolean
    }
)

const isValidationErrorValuesFalsy = <ValueType>(
  object: FormState<ValueType>["errors"]
) =>
  isEmptyObject(object) ||
  Object.values(object?.validationErrors as object).every((value) => !value)

/**
 * Copied the API from https://formik.org/, we had gotten approval from legal and technical
 * to use formik, but then after Peter's email we decided to not opt in for it.
 * This is a lighter weight solution, and only implements the features that we need so far.
 */
const useForm = <ValueType extends object>({
  initialErrors,
  hasInitialChanges = true,
  isValidateValid = isValidationErrorValuesFalsy,
  validate,
  onSubmit,
  submitListener,
  onChange = () => {},
  validateOnChange = false,
  validateOnBlur = false,
  animationTime = 0,
  ...args
}: UseFormProps<ValueType>) => {
  const storage = args.storage ?? sessionStorage

  const validateRef = useRefCallback(validate)
  const isValidateValidRef = useRefCallback(isValidateValid)
  const onSubmitRef = useRefCallback(onSubmit)
  const submitListenerRef = useRefCallback(submitListener)
  const onChangeRef = useRefCallback(onChange)

  const getInitialValues = (): ValueType => {
    if (isStorageArgs(args)) {
      const storedText = storage.getItem(args.storageKey)
      if (typeof args.initialValues === "function") {
        try {
          return args.initialValues(
            storedText ? tryJsonParse(storedText) : null
          )
        } catch {
          // if this caught, then the _initialValues function broke, therefore just use an empty object
          return {} as ValueType
        }
      } else {
        return storedText
          ? tryJsonParse(storedText) ?? args.initialValues
          : args.initialValues
      }
    } else if (typeof args.initialValues === "function") {
      return args.initialValues()
    } else {
      return args.initialValues
    }
  }

  const [state, setState] = React.useState<FormState<ValueType>>(() => {
    const initialValues = getInitialValues()
    let errors: FormState<ValueType>["errors"]
    if (!isDefined(initialErrors)) {
      errors = {}
    } else if (typeof initialErrors === "function") {
      errors = initialErrors(initialValues)
    } else {
      errors = initialErrors
    }
    return {
      values: initialValues,
      errors,
      isSubmitting: false,
      animating: false,
      hasChanges: hasInitialChanges
        ? Object.values(initialValues).reduce((accum, value) => {
            if (value) {
              return true
            }
            return accum
          }, false)
        : false,
      hasSubmitted: false,
      blurred: {},
    }
  })

  const animate = React.useCallback(async () => {
    setState((prevState) => ({ ...prevState, animating: true }))
    await sleep(animationTime)
    setState((prevState) => ({ ...prevState, animating: false }))
  }, [animationTime])
  const setBlur = React.useCallback(
    (
      errors: FormState<ValueType>["errors"] | undefined,
      blurred: FormState<ValueType>["blurred"]
    ) => {
      setState((prevState) => ({
        ...prevState,
        errors: errors || prevState.errors,
        blurred,
      }))
    },
    []
  )
  const setErrors = React.useCallback(
    (errors: FormState<ValueType>["errors"]) => {
      setState((prevState) => ({ ...prevState, errors }))
    },
    []
  )

  const setPartialErrors = React.useCallback(
    (errors: FormState<ValueType>["errors"]) => {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          validationErrors: {
            ...prevState.errors?.validationErrors,
            ...errors?.validationErrors,
          },
        },
      }))
    },
    []
  )
  const setSubmitting = React.useCallback((isSubmitting: boolean) => {
    setState((prevState) => ({ ...prevState, isSubmitting }))
  }, [])

  const start = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isSubmitting: true,
      errors: {},
      hasChanges: false,
    }))
  }, [])
  const setValidationErrors = React.useCallback(
    (errors: FormState<ValueType>["errors"]) => {
      setState((prevState) => ({
        ...prevState,
        errors,
        hasChanges: false,
      }))
    },
    []
  )
  const stop = React.useCallback(
    ({
      errors = {},
      isSubmitting = false,
      hasChanges = false,
      blurred,
    }: StopArgs<ValueType> = {}) => {
      setState((prevState) => ({
        ...prevState,
        blurred: blurred || prevState.blurred,
        errors,
        isSubmitting,
        hasChanges,
      }))
    },
    []
  )
  const clearStorageOnSubmit = isStorageArgs(args) && args.clearStorageOnSubmit
  const handleSubmit = React.useCallback(
    async (event?: CustomEvent, values = state.values, metadata?: any) => {
      event?.preventDefault?.()
      setState((prevState) => ({
        ...prevState,
        hasSubmitted: true,
      }))
      const validationResult =
        (await validateRef.current?.(values, {
          type: "submit",
          currentErrors: state.errors,
          blurred: state.blurred,
        })) ?? {}
      if (isValidateValidRef.current(validationResult)) {
        start()
        await onSubmitRef.current?.(values, {
          setSubmitting,
          setErrors,
          stop,
          animate,
          metadata,
        })
      } else {
        setValidationErrors(validationResult)
      }

      if (clearStorageOnSubmit) {
        storage.removeItem(args.storageKey)
      }
    },
    [
      animate,
      setErrors,
      setSubmitting,
      setValidationErrors,
      start,
      state.blurred,
      state.errors,
      state.values,
      stop,
      storage,
      clearStorageOnSubmit,
      args.storageKey,
    ]
  )
  const setValues = React.useCallback(
    async (values: ValueType) => {
      onChangeRef.current?.(values)

      setState((prevState) => ({
        ...prevState,
        values,
        hasChanges: true,
      }))
      if (validateOnChange) {
        const validate = validateRef.current
        const result = await (validate as NonNullable<typeof validate>)(
          values,
          {
            type: "change",
            currentErrors: state.errors,
            blurred: state.blurred,
          }
        )
        setErrors(result)
      }
      if (submitListenerRef.current?.(values)) {
        handleSubmit({ type: "submit" }, values)
      }
      if (args.storageKey) {
        storage.setItem(args.storageKey, JSON.stringify(values))
      }
    },
    [
      handleSubmit,
      setErrors,
      state.blurred,
      state.errors,
      storage,
      args.storageKey,
      validateOnChange,
    ]
  )
  const handleChange = React.useCallback(
    async (...events: CustomEvent[]) => {
      let values: ValueType = { ...state.values }
      events.forEach((event) => {
        const targetElement = event.target as HTMLInputElement
        values[targetElement.name as keyof ValueType] =
          targetElement.type === "checkbox"
            ? (targetElement.checked as ValueType[keyof ValueType])
            : (targetElement.value as ValueType[keyof ValueType])
      })
      await setValues(values)
    },
    [setValues, state.values]
  )

  const handleReset = async (event: CustomEvent) => {
    event.preventDefault?.()
    const initialValues = getInitialValues()
    if (validateOnChange && validateRef.current) {
      const result = await validateRef.current(initialValues, {
        type: "change",
        currentErrors: state.errors,
        blurred: state.blurred,
      })
      setErrors(result)
    }
    setValues(initialValues)
  }

  const handleBlur = async (event: CustomEvent) => {
    const blurred = {
      ...state.blurred,
      [(event.target as HTMLInputElement).name]: true,
    }
    let errors = validateOnBlur
      ? await validateRef.current?.(state.values, {
          type: "blur",
          currentErrors: state.errors,
          blurred,
        })
      : undefined

    setBlur(errors, blurred)
  }
  return {
    hasSubmitted: state.hasSubmitted,
    values: state.values,
    hasChanges: state.hasChanges,
    handleBlur,
    blurred: state.blurred,
    handleChange,
    handleSubmit,
    isSubmitting: state.isSubmitting,
    errors: state.errors,
    animating: state.animating,
    setErrors,
    setPartialErrors,
    animate,
    handleReset,
    setValues,
  }
}

export default useForm

/**
 * This typeguard is a workaround for downstream code re-typechecking this file with
 * `strictNullChecks: false` (undefined isn't part of that type system, so narrowing with it doesn't work).
 */
const isStorageArgs = (args: {
  storageKey?: string
}): args is { storageKey: string } => {
  return typeof args.storageKey === "string"
}

const tryJsonParse = (json: string) => {
  try {
    return JSON.parse(json)
  } catch {
    return null
  }
}
