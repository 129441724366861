import React from "react"
// eslint-disable-next-line no-restricted-imports
import { FloatingPortal as _FloatingPortal } from "@floating-ui/react"

const FloatingPortalRootContext = React.createContext<HTMLElement | null>(null)

/**
 * Provides a new floating portal root element at the end of the containing dom node.
 *
 * Useful for "toplevel" elements like <dialog>.
 */
export const FloatingPortalRoot = (props: {
  className?: string
  testid?: string
  children?: React.ReactNode
}) => {
  const [root, setRoot] = React.useState<HTMLDivElement | null>(null)

  return (
    <FloatingPortalRootContext.Provider value={root}>
      {props.children}
      <div
        className={props.className}
        ref={setRoot}
        data-testid={props.testid}
      />
    </FloatingPortalRootContext.Provider>
  )
}

/**
 * FloatingPortal with default roots configured.
 */
export const FloatingPortal = (
  props: React.ComponentProps<typeof _FloatingPortal>
) => {
  const contextRoot = React.useContext(FloatingPortalRootContext)
  const root = props.root ?? contextRoot

  return (
    <_FloatingPortal
      {...props}
      root={root}
      // priority order for setting the root element (root or id props):
      // 1. `props.id` has the highest priority
      // 2. `props.root`
      // 3. `FloatingPortalRootContext` value
      // 4. `"modal-root"` id
      // #modal-root element is defined in the html.ejs template (webpack generates html from it)
      // id needs to be `undefined` for _FloatingPortal to use the `root` prop instead of the `id` prop
      id={props.id ?? (root ? undefined : "modal-root")}
    />
  )
}
