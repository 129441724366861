import React from "react"
import { css, useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import RtlSvg from "src/icons/rtl-svg"
import { iconSizeCss } from "src/styles/icon-utils"

const Svg = styled(RtlSvg)<{
  size: "large" | "medium" | "small"
  variant: "left" | "right"
}>`
  ${(props) => iconSizeCss(props.size)};

  ${(props) =>
    props.size === "small" &&
    props.variant === "right" &&
    css`
      transform: scale(-1, 1);
    `};
`

const Arrow = (props: {
  size?: "large" | "medium" | "small"
  variant?: "left" | "right"
}) => {
  const size = props.size ?? "large"
  const variant = props.variant ?? "left"
  const theme = useTheme()

  if (size === "small") {
    return (
      <Svg
        variant={variant}
        focusable="false"
        size="small"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.05713 8L7.52853 12.4714L8.47134 11.5286L5.60941 8.66667L11.9999 8.66667L11.9999 7.33333L5.60941 7.33333L8.47134 4.4714L7.52853 3.52859L3.05713 8Z"
          fill={theme.palette.foreground.default}
        />
      </Svg>
    )
  } else if (size === "medium") {
    return (
      <Svg
        variant={variant}
        size={size}
        focusable="false"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {variant === "left" ? (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.99988 12L12.707 18.7071L14.1212 17.2928L9.8283 13L19.4141 13V11L9.8283 11L14.1212 6.70706L12.707 5.29285L5.99988 12Z"
            strokeWidth="2"
            fill={theme.palette.foreground.default}
          />
        ) : (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 12L11.2929 18.7071L9.87868 17.2928L14.1716 13L4.58579 13V11L14.1716 11L9.87868 6.70706L11.2929 5.29285L18 12Z"
            strokeWidth="2"
            fill={theme.palette.foreground.default}
          />
        )}
      </Svg>
    )
  } else {
    return (
      <Svg
        variant={variant}
        size={size}
        focusable="false"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {variant === "left" ? (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.58582 16L15.2929 24.7071L16.7071 23.2928L10.4142 17L24 17L24 15L10.4142 15L16.7071 8.70706L15.2929 7.29285L6.58582 16Z"
            strokeWidth="2"
            fill={theme.palette.foreground.default}
          />
        ) : (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.4143 16L16.7072 24.7071L15.293 23.2928L21.5859 17L8.00009 17L8.00009 15L21.5859 15L15.293 8.70706L16.7072 7.29285L25.4143 16Z"
            strokeWidth="2"
            fill={theme.palette.foreground.default}
          />
        )}
      </Svg>
    )
  }
}

export default Arrow
