/**
 * @module
 * Typography tokens were originally from https://docs.google.com/spreadsheets/d/1PeQ50ubabukp0nLd1Zr1iy4Fo2UtSoNh_zd2apy2dXs
 *
 * More up-to-date tokens can be found on zeroheight: https://zeroheight.com/9d84607ad/v/latest/p/108f94-typography-tokens
 */

import { css, type Theme } from "@emotion/react"
import styled from "@emotion/styled"

/**
 * Adds appropriate font-variantion-settings for italic text.
 *
 * `font-style: italic` doesn't work with the current Inter v3 variable font.
 *
 * @see https://jira.veevadev.com/browse/MYVC-25034
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export const fontStyleItalicCss = (_theme: Theme) => css`
  font-variation-settings: "slnt" -10;
`

export const semibold40Css = (theme: Theme) => css`
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  margin: 0;

  ${theme.isRTL &&
  css`
    font-weight: 700;
  `};
`
export const Semibold40 = styled.h2`
  ${(props) => semibold40Css(props.theme)};
`

export const Medium24Header1 = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  margin: 0;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 400;
    `};
`
export const Medium28 = styled.h2`
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2.125rem;
  margin: 0;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 400;
    `};
`
export const semibold28Css = (theme: Theme) => css`
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.125rem;
  margin: 0;

  ${theme.isRTL &&
  css`
    font-weight: 700;
  `};
`
export const Semibold28 = styled.h2`
  ${(props) => semibold28Css(props.theme)};
`
export const Medium24 = styled.h3`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  margin: 0;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 400;
    `};
`
export const Semibold24 = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 700;
    `};
`
export const Medium24Header4 = styled.h4`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  margin: 0;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 400;
    `};
`
export const Medium20Header2 = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin: 0;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 400;
      line-height: 1.625rem;
    `};
`
export const Medium20 = styled.h4`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin: 0;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 400;
      line-height: 1.625rem;
    `};
`
export const Semibold20 = styled.h4`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 700;
      line-height: 1.625rem;
    `};
`
export const Medium16Heading = styled.h5`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 400;
      line-height: 1.5rem;
    `};
`
export const Overline12 = styled.h5`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin: 0;
`
export const Medium16Body = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 400;
      line-height: 1.5rem;
    `};
`

export const semibold16Css = (theme: Theme) => css`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;

  ${theme.isRTL &&
  css`
    font-weight: 700;
    line-height: 1.5rem;
  `};
`
export const Semibold16 = styled.p`
  ${(props) => semibold16Css(props.theme)};
`
export const regular16Css = (theme: Theme) => css`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;

  ${theme.isRTL &&
  css`
    line-height: 1.5rem;
  `};
`
export const Regular16 = styled.p`
  ${(props) => regular16Css(props.theme)};
`
export const Regular16Paragraph = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
`
export const semibold14Css = (theme: Theme) => css`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;

  ${theme.isRTL &&
  css`
    font-weight: 700;
    line-height: 1.375rem;
  `};
`
export const Semibold14 = styled.p`
  ${(props) => semibold14Css(props.theme)};
`

export const medium14Css = (theme: Theme) => css`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;

  ${theme.isRTL &&
  css`
    font-weight: 400;
    line-height: 1.375rem;
  `};
`
export const Medium14 = styled.p`
  ${(props) => medium14Css(props.theme)};
`
export const Medium14Label = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 400;
      line-height: 1.375rem;
    `};
`
export const regular14UICss = (theme: Theme) => css`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;

  ${theme.isRTL &&
  css`
    line-height: 1.375rem;
  `};
`
export const Regular14UI = styled.p`
  ${(props) => regular14UICss(props.theme)};
`

export const Regular14UIInput = styled.input`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;

  ${(props) =>
    props.theme.isRTL &&
    css`
      line-height: 1.375rem;
    `};
`
export const regular14ParagraphCss = (theme: Theme) => css`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;

  ${theme.isRTL &&
  css`
    line-height: 1.375rem;
  `};
`
export const Regular14Paragraph = styled.p`
  ${(props) => regular14ParagraphCss(props.theme)};
`
export const Semibold12 = styled.p`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;

  ${(props) =>
    props.theme.isRTL &&
    css`
      font-weight: 700;
    `};
`

export const medium12Css = (theme: Theme) => css`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25rem;

  ${theme.isRTL &&
  css`
    font-weight: 400;
  `};
`
export const Medium12 = styled.p`
  ${(props) => medium12Css(props.theme)};
`

export const regular12Css = () => css`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25rem;
`
export const Regular12 = styled.p`
  ${regular12Css()};
`
export const Regular12UI = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
`

export const Medium10 = styled.p`
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1rem;
`
