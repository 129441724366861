import React from "react"
import useRefCallback from "src/hooks/use-ref-callback"

/**
 * Sets up an event handler for mouse down events outside of an element.
 *
 * This hook implementation also does NOT treat mouse down events on children content
 * in React portals as being an outside click.
 *
 * @example
 * The props object returned should be spread onto the desired element to detect clicks outside of:
 *
 * ```tsx
 * const clickOutsideProps = useOnClickOutside(...)
 *
 * return <div {...clickOutsideProps}>content</div>
 * ```
 *
 * prior art:
 * @see https://github.com/facebook/react/issues/19637#issuecomment-850412650
 * @see https://react.dev/reference/react-dom/components/common#handling-focus-events
 */
export const useOnClickOutside = (
  onClickOutside: (event: MouseEvent) => void,
  args?: {
    active?: boolean
  }
) => {
  const isClickInsideRef = React.useRef(false)
  // If a mousedown event happens inside, then this react event handler will fire first
  const onMouseDown = React.useCallback(() => {
    if (args?.active !== false) {
      // store `true` so we can read this in the document `mousedown` handler below
      isClickInsideRef.current = true
    }
  }, [args?.active])

  const onClickOutsideCallbackRef = useRefCallback(onClickOutside)
  React.useEffect(() => {
    if (args?.active === false) {
      return
    }
    const handleMouseDown = (event: MouseEvent) => {
      if (!isClickInsideRef.current) {
        // the click was outside, fire the outside click handler
        onClickOutsideCallbackRef.current(event)
      } else {
        // the click was inside, so reset and ignore this click event
        isClickInsideRef.current = false
      }
    }

    document.addEventListener("mousedown", handleMouseDown)
    return () => {
      document.removeEventListener("mousedown", handleMouseDown)
    }
  }, [args?.active])

  return { onMouseDown }
}
