import React from "react"
import { IntlProvider } from "react-intl"
import { type Locale as DateFnsLocale } from "date-fns"

const IntlStateContext = React.createContext<{
  locale: string
  updateIntl: (locale?: string) => void
  rtl: boolean
  dateFnsLocale: DateFnsLocale
} | null>(null)

export const useI18nState = () => {
  const value = React.useContext(IntlStateContext)
  if (value === null) {
    throw new Error("Missing I18nStateProvider")
  }
  return value
}

export const useRTL = () => {
  return useI18nState().rtl
}

export const I18nStateProvider = (props: {
  locale: string
  dateFnsLocale: DateFnsLocale
  updateIntl: (locale?: string) => void
  messages: Record<string, string>
  children?: React.ReactNode
  rtl: boolean
}) => {
  const value = React.useMemo(
    () => ({
      locale: props.locale,
      updateIntl: props.updateIntl,
      rtl: props.rtl,
      dateFnsLocale: props.dateFnsLocale,
    }),
    [props.locale, props.updateIntl, props.rtl, props.dateFnsLocale]
  )

  return (
    <IntlStateContext.Provider value={value}>
      <IntlProvider locale={props.locale} messages={props.messages}>
        {props.children}
      </IntlProvider>
    </IntlStateContext.Provider>
  )
}
