import { css, type Theme } from "@emotion/react"

export const KEYS = {
  tab: "Tab",
  arrowDown: "ArrowDown",
  arrowLeft: "ArrowLeft",
  arrowRight: "ArrowRight",
  arrowUp: "ArrowUp",
  backspace: "Backspace",
  enter: "Enter",
  escape: "Escape",
  space: " ",
}
// https://testing-library.com/docs/ecosystem-user-event/#special-characters
export const TEST_KEYS = {
  arrowDown: "{arrowdown}",
  arrowLeft: "{arrowleft}",
  arrowRight: "{arrowright}",
  arrowUp: "{arrowup}",
  backspace: "{backspace}",
  enter: "{Enter}",
  escape: "{Escape}",
  space: "[Space]",
}

export const headerHeight = 52
// TODO: MYVC-26140
export const subHeaderHeight = 48
// TODO: MYVC-26140
export const totalHeaderHeight = headerHeight + subHeaderHeight

export const headerCss = (theme: Theme) => css`
  background-color: ${theme.palette.oneOff.studio};
  height: ${headerHeight}px;
  padding-inline: 24px 20px;
`

export const VAULT_CLINICAL_URL = "https://clinical.veevavault.help/en/gr"

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const IMAGE_TYPE_MAPPING = {
  "image/gif": "GIF",
  "image/jpeg": "JPEG",
  "image/png": "PNG",
}
export const VIDEO_TYPE_MAPPING = {
  "video/mp4": "MP4",
}

/**
 * Example domains may be https://patients-us-dev-ft.myveevatrials.com, https://patients.myveeva.com, https://sponsors-us.local.com:3005
 *
 * These would return myveevatrials.com, myveeva.com, local.com:3005 respectively
 */
export const GLOBAL_DOMAIN = window.location.hostname.substring(
  window.location.hostname.indexOf(".") + 1
)

/**
 * These are from the backend https://gitlab.veevadev.com/myveeva-clinicaltrials/jenkins-libraries/-/blob/develop/vars/translationQualityControl.groovy?ref_type=heads#L9
 */
export const DO_NOT_TRANSLATE_CONSTANTS = {
  myveevaforpatients: "MyVeeva for Patients",
  veevasystems: "Veeva Systems",
  veevasystemsinc: "Veeva Systems Inc.",
  myveevastudio: "MyVeeva Studio",
  econsent: "eConsent",
  veevaEconsent: "Veeva eConsent",
  icf: "ICF",
  myveeva: "MyVeeva",
  sitevault: "SiteVault",
  vault: "Vault",
  uat: "UAT",
  veeva: "Veeva",
  veevaecoa: "Veeva eCOA",
  epro: "ePRO",
  ecoa: "eCOA",
  eclinro: "eClinRO",
  clinro: "ClinRO",
  awscloudtrail: "AWS CloudTrail",
  awsguardduty: "AWS Guard Duty",
  awsshield: "AWS Shield",
  aws: "AWS",
  cloudtrail: "CloudTrail",
  amazonwebservices: "Amazon Web Services",
} as const
