import React from "react"
import useRefCallback from "src/hooks/use-ref-callback"

const STORAGE_UNDEFINED = Symbol()

/**
 * @param storageValue The currently stored value. This is a `symbol` when no value is stored.
 */
export type StorageStateSetter<T> = (storageValue: T | symbol) => T

const useStorageState = <T>(
  initialValue: T | StorageStateSetter<T>,
  key: string,
  {
    storage = sessionStorage,
    callInitialValueOnMount = false,
    onStorageWrite,
  }: {
    storage?: Storage
    callInitialValueOnMount?: boolean
    onStorageWrite?: (key: string, value: string) => void
  } = {}
): [
  value: T,
  setValue: (newValue: T | ((prevValue: T) => T)) => void,
  removeValue: () => void,
] => {
  if (!key) {
    throw new Error("key is missing in useStorageState")
  }
  const [storageValue] = React.useState(() => {
    let storageValue = storage.getItem(key)
    // If running a unit test and you get a stacktrace pointing here - you may need to JSON.stringify your value
    return storageValue && storageValue !== "undefined"
      ? (JSON.parse(storageValue) as T)
      : STORAGE_UNDEFINED
  })

  const onStorageWriteRef = useRefCallback(onStorageWrite)

  const [, triggerRerender] = React.useState<unknown>()
  const value = React.useRef<T | null>(null)
  if (value.current === null) {
    // useRef does not accept functions for initialValue
    // checking for STORAGE_UNDEFINED is for when the value got set to null
    if (callInitialValueOnMount || storageValue === STORAGE_UNDEFINED) {
      value.current =
        // NOTE: `as StorageStateSetter` is needed because T isn't restricted to all types except functions
        typeof initialValue === "function"
          ? (initialValue as StorageStateSetter<T>)(storageValue)
          : initialValue
    } else {
      value.current = storageValue
    }
    const jsonValue = JSON.stringify(value.current)
    storage.setItem(key, jsonValue)
    onStorageWriteRef.current?.(key, jsonValue)
  }
  const setValue = React.useCallback(
    (newValue: T | ((prevValue: T) => T)): void => {
      value.current =
        typeof newValue === "function"
          ? (newValue as (prevValue: T) => T)(value.current as T)
          : newValue

      const jsonValue = JSON.stringify(value.current)
      storage.setItem(key, jsonValue)
      triggerRerender(value.current)
      onStorageWriteRef.current?.(key, jsonValue)
    },
    [key, storage]
  )
  return [
    value.current,
    setValue,
    React.useCallback(() => storage.removeItem(key), [key, storage]),
  ]
}

export default useStorageState
