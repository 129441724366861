import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import RadioIcon from "src/icons/radio"
import { Medium16Body } from "src/typography"
import { KEYS } from "src/constants"
import useMergeRefs from "src/hooks/use-merge-refs"

const OptionsBody = styled.div<{ scrollable: boolean }>`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.border.default};
  border-radius: 8px;
  ${(props) =>
    props.scrollable &&
    css`
      overflow: auto;
      height: 100%;
    `}
`
const RadioItem = styled.div<{ selected: boolean }>`
  width: 100%;
  min-height: 56px;
  height: fit-content;
  padding: 8px 4px 8px 14px;
  gap: 10px;
  display: flex;
  align-items: center;

  border-bottom: 1px solid ${(props) => props.theme.palette.border.default};
  :first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  :last-of-type {
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  background-color: ${(props) =>
    props.selected
      ? props.theme.palette.surface.brand.selected.default
      : props.theme.palette.surface.default};
  &[aria-disabled="true"] {
    background-color: ${(props) => props.theme.palette.surface.disabled};
  }
  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.selected
        ? props.theme.palette.surface.brand.selected.hovered
        : props.theme.palette.surface.hovered};
    &[aria-disabled="true"] {
      cursor: not-allowed;
      background-color: ${(props) => props.theme.palette.surface.disabled};
    }
  }
  > div {
    width: calc(100% - 60px);
  }
  > div > label {
    width: 100%;
  }
`
const RowTextContainer = styled.div<{ isDisabled: boolean }>`
  width: 100%;
  overflow: hidden;
  white-space: wrap;
  overflow-wrap: break-word;
  :hover {
    cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  }
`
const RadioSheetItem = (props: {
  index: number
  itemRefs: React.MutableRefObject<(HTMLDivElement | null)[]>
  selectedRef: React.RefObject<HTMLDivElement>
  isFocused: boolean
  isSelected: boolean
  listItems: {
    value: string
    content: React.ReactNode
    disabled?: boolean
  }[]
  value: string
  name: string
  onChange: (e: {
    detail: number
    target: { name: string; value: string }
  }) => void
  renderContentTypography?: (content: React.ReactNode) => React.ReactNode
  isRTL?: boolean
  automationId?: string
}) => {
  // setting this up for when we support RTL languages
  const isRTL = props.isRTL ?? false

  const { index, itemRefs } = props
  const setRef = React.useCallback(
    // when the ref is invoked, it will set the current element as the correct item in the array
    // this allows its focus to be controlled in the onKeyDown handler
    (element: HTMLDivElement | null) => {
      if (element) {
        itemRefs.current[index] = element
      }
    },
    [itemRefs, index]
  )
  const ref = useMergeRefs([
    setRef,
    props.isSelected || props.isFocused ? props.selectedRef : null,
  ])

  const item = props.listItems[index]
  return (
    <RadioItem
      tabIndex={props.isSelected || props.isFocused ? 0 : -1}
      role="radio"
      ref={ref}
      key={index}
      selected={props.isSelected}
      aria-checked={props.isSelected && !item.disabled}
      aria-disabled={!!item.disabled}
      onKeyDown={(e) => {
        let targetInd = index
        if (isRTL) {
          if (e.key === KEYS.arrowUp || e.key === KEYS.arrowRight) {
            targetInd--
          } else if (e.key === KEYS.arrowDown || e.key === KEYS.arrowLeft) {
            targetInd++
          }
        } else {
          if (e.key === KEYS.arrowUp || e.key === KEYS.arrowLeft) {
            targetInd--
          } else if (e.key === KEYS.arrowDown || e.key === KEYS.arrowRight) {
            targetInd++
          }
        }
        targetInd =
          // allow the index to wrap around
          ((targetInd % props.listItems.length) + props.listItems.length) %
          props.listItems.length
        if (targetInd !== index) {
          e.preventDefault()
          itemRefs.current[targetInd]?.focus()
          props.onChange({
            detail: e.detail,
            target: {
              value: props.listItems[targetInd].value,
              name: props.name,
            },
          })
        }
      }}
      onClick={(e) => {
        if (!item.disabled) {
          props.onChange({
            detail: e.detail,
            target: { value: item.value, name: props.name },
          })
        }
      }}
      data-automationid={props.automationId ? props.automationId : undefined}
    >
      <RadioIcon
        selected={props.isSelected && !item.disabled}
        disabled={!!item.disabled}
      />
      <RowTextContainer isDisabled={!!item.disabled}>
        {props.renderContentTypography ? (
          props.renderContentTypography(item.content)
        ) : (
          <Medium16Body>{item.content}</Medium16Body>
        )}
      </RowTextContainer>
    </RadioItem>
  )
}

const RadioSheet = (props: {
  listItems: {
    value: string
    content: React.ReactNode
    disabled?: boolean
    automationId?: string
  }[]
  value: string
  name: string
  onChange: (e: {
    detail: number
    target: { name: string; value: string }
  }) => void
  renderContentTypography?: (content: React.ReactNode) => React.ReactNode
  scrollable?: boolean
  focusOnMount?: boolean
}) => {
  const scrollable = props.scrollable ?? true

  const itemRefs = React.useRef<(HTMLDivElement | null)[]>([])
  React.useEffect(() => {
    // cleanup incase the number of items changes
    itemRefs.current = itemRefs.current.slice(0, props.listItems.length)
  }, [props.listItems.length])

  const selectedRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (props.focusOnMount) {
      selectedRef.current?.focus?.()
    }
    selectedRef.current?.scrollIntoView?.(false)
  }, [props.focusOnMount])

  const selectedIndex = props.listItems.findIndex(
    (item) => props.value === item.content || props.value === item.value
  )
  const focusedIndex =
    selectedIndex !== -1
      ? selectedIndex
      : props.listItems.findIndex((item) => !item.disabled)

  return (
    <OptionsBody scrollable={scrollable} role="radiogroup">
      {props.listItems.map((item, index) => {
        return (
          <RadioSheetItem
            isFocused={focusedIndex === index}
            isSelected={selectedIndex === index}
            index={index}
            listItems={props.listItems}
            name={props.name}
            onChange={props.onChange}
            renderContentTypography={props.renderContentTypography}
            itemRefs={itemRefs}
            selectedRef={selectedRef}
            key={index}
            value={props.value}
            automationId={item.automationId ? item.automationId : undefined}
          />
        )
      })}
    </OptionsBody>
  )
}

export default RadioSheet
