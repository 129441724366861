import React from "react"
import {
  type IconProps,
  iconColorCss,
  iconSizeCss,
} from "src/styles/icon-utils"

const Times = (props: IconProps) => (
  <svg
    className={props.className}
    focusable="false"
    css={[iconColorCss(props.color), iconSizeCss(props.size)]}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 5L12 12M19 19L12 12M12 12L19 5M12 12L5 19"
      stroke="var(--color)"
      strokeWidth="2"
    />
  </svg>
)

export default Times
