import React from "react"
import ReactDOM from "react-dom"
import Popup, { type PopupProps } from "src/components/popup"
import { css } from "@emotion/react"
import { LocaleRegion } from "src/ui-providers"

export interface ModalProps extends PopupProps {
  backgroundOnly?: boolean
  backgroundVariant?: "default" | "light" | "no-opacity"
  freezeBackground?: boolean
  modalRoot?: HTMLElement
  modalRef?: React.Ref<HTMLDivElement>
}

class Modal extends React.Component<ModalProps> {
  el = document.createElement("div")

  componentDidMount() {
    const modalRoot =
      this.props.modalRoot ?? document.getElementById("modal-root")
    modalRoot?.appendChild(this.el)
    if (this.props.freezeBackground) {
      // Don't allow scroll of the background while the modal is open
      document.body.style.overflow = "hidden"
    }
  }

  componentWillUnmount() {
    // NOTE: this.el.remove() doesn't work on IE11
    this.el.parentNode?.removeChild(this.el)
    if (this.props.freezeBackground) {
      document.body.style.overflow = ""
    }
  }

  render() {
    const { className, freezeBackground, backgroundOnly, modalRef, ...props } =
      this.props
    const titlePosition = props.titlePosition ?? "left"
    const title = props.title ?? ""
    const closeTitle = props.closeTitle ?? ""
    const clearOnClickOutside = props.clearOnClickOutside ?? false
    const backgroundVariant = props.backgroundVariant ?? "default"
    return ReactDOM.createPortal(
      <LocaleRegion
        css={[
          css`
            position: fixed;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 999;
            #modal-popup {
              height: 100%;
              position: inherit;
              z-index: 1000;
            }
          `,
          (() => {
            switch (backgroundVariant) {
              case "default":
                return css`
                  background-color: rgba(0, 0, 0, 0.5);
                `
              case "light":
                return css`
                  background-color: rgba(0, 0, 0, 0.2);
                `
              case "no-opacity":
              default:
                return null
            }
          })(),
        ]}
        className={className}
      >
        {backgroundOnly ? null : (
          <Popup
            {...props}
            titlePosition={titlePosition}
            title={title}
            closeTitle={closeTitle}
            clearOnClickOutside={clearOnClickOutside}
            ref={modalRef}
            topOffset={props.topOffset || 0}
            id="modal-popup"
          >
            {props.children}
          </Popup>
        )}
      </LocaleRegion>,
      this.el
    )
  }
}

const ModalExport = (props: ModalProps) => {
  if (!props.visible) {
    return null
  } else {
    return <Modal {...props} />
  }
}

export default ModalExport
