/// <reference types="../boomr.d.ts" />
import { uuid } from "src/functions/uuid"

const isBoomerangEnabled = () =>
  process.env.REACT_APP_BOOMERANG === "true" && !!window.BOOMR

/**
 * https://akamai.github.io/boomerang/BOOMR.html#.addVar
 *
 * For a list of all names, see https://wiki.veevadev.com/display/MYVC/Page+Metrics
 *
 * Go to https://wiki.veevadev.com/pages/viewpage.action?pageId=261168380#PageMetrics-Proposedchangestobeacontoadddevicedata,andlogeventdata if you'd like to log data
 * specifically for devs unrelated to product requirements
 */
export const logger = (name: string, data = {}) => {
  if (isBoomerangEnabled()) {
    let activity = BOOMR.getVar("activity") || []
    activity.push({
      activity: name,
      activity_id: uuid(),
      activity_at: Date.now(),
      activity_properties: data,
    })
    BOOMR.addVar("activity", activity, true)
    // Marking this plugin will trigger the beacon to be sent
    BOOMR.plugins.RT.done()
    BOOMR.sendBeacon()
  }
}

/**
 * https://akamai.github.io/boomerang/BOOMR.plugins.RT.html#.startTimer__anchor
 */
export const startTimer = (name: string) => {
  if (isBoomerangEnabled()) {
    BOOMR.plugins.RT.startTimer(name)
  }
}

/**
 * https://akamai.github.io/boomerang/BOOMR.plugins.RT.html#.endTimer__anchor
 */
export const endTimer = (
  name: string,
  {
    name: metadataName,
    ...metadata
  }: { name?: string } & Record<string, string | boolean | number> = {}
) => {
  if (isBoomerangEnabled()) {
    BOOMR.plugins.RT.endTimer(name)
    if (metadataName) {
      logger(metadataName, metadata)
    }
  }
}

export const endTimerWithPromise = async <T>(
  promise: Promise<T>,
  name: string,
  metadata?: { name?: string } & Record<string, string | boolean>
) => {
  const result = await promise
  endTimer(name, metadata)
  return result
}
