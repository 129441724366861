import { getDateFnsLanguageLocaleFile } from "src/i18n/locale-helpers"
import defaultDateFnsLocale from "date-fns/locale/en-US"

export const createLoadLocale = (args: {
  loadMessages: readonly (
    | {
        name: string
        load: (messagesKey: string) => Promise<Record<string, string>>
      }
    | Record<string, string>
  )[]
}) => {
  async function loadMessages(locale: string): Promise<Record<string, string>> {
    const messagesKey = locale.replaceAll("-", "")

    const loaderResults = await Promise.all(
      args.loadMessages.map(async (loader) => {
        // NOTE: this map function ^ is `async` in case the `loader.load` function throws synchronously.
        if ("load" in loader && typeof loader.load === "function") {
          try {
            return await loader.load(messagesKey)
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            // eslint-disable-next-line no-console
            console.error(
              `Failed to load ${messagesKey} messages from ${loader.name}`
            )
            // ignore rejected promises, we assume `loadMessages` has en-US fallbacks.
            return undefined
          }
        } else {
          return loader
        }
      })
    )

    return Object.assign({}, ...loaderResults)
  }

  return async function loadLocale(locale: string) {
    const [messages, dateFnsLocale] = await Promise.all([
      loadMessages(locale),
      getDateFnsLanguageLocaleFile(locale),
    ])
    return { messages, dateFnsLocale: dateFnsLocale ?? defaultDateFnsLocale }
  }
}
