import React from "react"
import styled from "@emotion/styled"
import {
  Medium24Header4,
  Regular14Paragraph,
  Regular14UI,
  Semibold14,
} from "@myvp/shared/src/typography"
import { useIntl } from "react-intl"
import { css } from "@emotion/react"
import RadioSheet from "@myvp/shared/src/components/radio-sheet"
import Button from "@myvp/shared/src/components/buttons/button"
import useForm from "@myvp/shared/src/hooks/use-form"
import { routeNames } from "src/router/route-names"
import { Navigate, useLocation, useMatch, useNavigate } from "react-router-dom"
import * as authModel from "src/models/auth.model"
import * as registerLegacyModel from "src/pages/register/models/register-legacy.model"
import { useMutation } from "@tanstack/react-query"
import { isSessionValid } from "@myvp/shared/src/functions/is-session-valid"
import { MfaType } from "src/types"
import { GetUserMetadataQuery } from "src/gql/graphql"
import jsCookie from "js-cookie"
import { isEmptyObject } from "@myvp/shared/src/functions/is-empty-object"
import useDidUpdate from "@myvp/shared/src/hooks/use-did-update"
import { GLOBAL_DOMAIN } from "@myvp/shared/src/constants"
import WorkflowCard from "src/pages/workflow-card"
import breakpoints from "@myvp/shared/src/styles/breakpoints"
import useStorageState from "@myvp/shared/src/hooks/use-storage-state"
import { refreshOpenTabs } from "src/functions/refresh-open-tabs"
import { getErrorCode } from "@myvp/shared/src/functions/get-error-code"
import { HttpCode } from "@myvp/shared/src/types"
import { PhoneErrorModal } from "src/components/phone-mfa-form"

const Form = styled.form`
  width: 100%;
  height: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
`
const RadioItem = styled.div`
  width: fit-content;
  ${Semibold14} {
    color: ${(props) => props.theme.palette.foreground.default};
  }
  ${Regular14UI} {
    color: ${(props) => props.theme.palette.foreground.alt};
  }
`
// TODO MYVC-19747 - see which keys can be removed
const KEYS = [
  `mfa-sent-timer:${MfaType.Sms}`,
  `mfa-sent-timer:${MfaType.Email}`,
  `mfa-sent-timer:${MfaType.Call}`,
] as const

export const cleanupMfaStorage = () => {
  KEYS.forEach((key) => {
    sessionStorage.removeItem(key)
  })
}
export const removeFromMfaStorage = (keys: (typeof KEYS)[number][]) => {
  keys.forEach((key) => {
    sessionStorage.removeItem(key)
  })
}

type LocationState = GetUserMetadataQuery & {
  bearerToken: string
  relatedUserId?: string
  notificationId?: string
}
const DomainRedirectWrapper = () => {
  const navigate = useNavigate()
  const { pathname, search, state } = useLocation()
  let locationState: LocationState = { ...state } ?? {}
  const redirectCookie = jsCookie.get("redirect-state")
  const isLegacyRegistration = useMatch(
    routeNames.registerLegacyConfirmIdentity
  )

  React.useEffect(() => {
    const abortController = new AbortController()
    window.addEventListener(
      "storage",
      (event) => {
        if (
          event.storageArea === localStorage &&
          event.key === "myvp-psesttl" &&
          event.oldValue !== event.newValue &&
          !redirectCookie
        ) {
          navigate({ pathname: routeNames.login })
          cleanupMfaStorage()
        }
      },
      { signal: abortController.signal }
    )
    return () => {
      abortController.abort()
    }
  }, [navigate, redirectCookie])

  useDidUpdate(
    locationState,
    function getLocationStateFromCookie() {
      if (isEmptyObject(locationState) && redirectCookie) {
        try {
          const redirectState = JSON.parse(redirectCookie)
          if (redirectState["myvp-sesttl"]) {
            localStorage.setItem("myvp-sesttl", redirectState["myvp-sesttl"])
          }
          if (redirectState["myvp-psesttl"]) {
            localStorage.setItem("myvp-psesttl", redirectState["myvp-psesttl"])
          }
          navigate(
            { pathname, search },
            {
              state: {
                bearerToken: redirectState.bearerToken,
                userInfo: { ...redirectState.userInfo },
                formattedPhoneNumber: redirectState.formattedPhoneNumber,
              },
            }
          )
        } catch {}
      }
    },
    { runOnInitialMount: true }
  )

  // Cookie check needed in case this block executes while we are still setting locationState
  if (
    !redirectCookie &&
    !isLegacyRegistration &&
    (isEmptyObject(locationState) || !isSessionValid(true))
  ) {
    return <Navigate to={{ pathname: routeNames.login, search }} />
  }

  if (isEmptyObject(locationState)) {
    return null
  }

  jsCookie.remove("redirect-state", {
    domain: GLOBAL_DOMAIN,
  })
  return <ConfirmIdentity />
}
const ConfirmIdentity = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { search, state } = useLocation()
  const [errorModal, setErrorModal] = React.useState<React.ReactNode>()
  let locationState: LocationState = { ...state } ?? {}
  const isLegacyRegistration = useMatch(
    routeNames.registerLegacyConfirmIdentity
  )
  const [preferredMfaType, setPreferredMfaType] = useStorageState(
    MfaType.Sms,
    "mfa-type",
    {
      storage: localStorage,
    }
  )

  const formattedPhoneNumber =
    locationState.userInfo?.formattedPhoneNumber ?? ""
  const email = locationState.userInfo?.email ?? ""
  const isEmailOnlyUser = email && !formattedPhoneNumber

  const handleSendMfaError = (error: Error) => {
    const errorCode = getErrorCode(error)
    if (errorCode === HttpCode.Unauthorized) {
      navigate({ pathname: routeNames.login, search })
    } else {
      setErrorModal(
        <PhoneErrorModal
          errorCode={errorCode as HttpCode}
          onClose={() => setErrorModal(null)}
        />
      )
    }
  }

  const sendMfaLegacy = useMutation({
    mutationFn: () =>
      registerLegacyModel.sendMfa({
        mfaType: values["mfa-type"],
        relatedUserId: locationState.relatedUserId as string,
        notificationId: locationState.notificationId as string,
      }),
    throwOnError: false,
    onSuccess: () => {
      // incase the user navigated back to this page from text/voice MFA
      // this allows the user to change MFA types
      removeFromMfaStorage([`mfa-sent-timer:${values["mfa-type"]}`])
      navigate(
        { pathname: routeNames.registerLegacyConfirmPhone, search },
        {
          state: {
            ...locationState,
            mfaType: values["mfa-type"],
            formattedPhoneNumber,
          },
        }
      )
    },
    onError: handleSendMfaError,
  })

  const sendMfa = useMutation({
    mutationFn: (params: { email?: string; mfaType?: MfaType }) =>
      authModel.resendMfaCode(params),
    throwOnError: false,
    onSuccess: () => {
      if (values["mfa-type"] === MfaType.Email) {
        removeFromMfaStorage([`mfa-sent-timer:${values["mfa-type"]}`])
        navigate(
          { pathname: routeNames.confirmIdentityEmail, search },
          {
            state: {
              ...locationState,
              isError: false,
            },
          }
        )
      } else {
        // incase the user navigated back to this page from text/voice MFA
        // this allows the user to change MFA types
        removeFromMfaStorage([`mfa-sent-timer:${values["mfa-type"]}`])
        navigate(
          { pathname: routeNames.confirmIdentityPhone, search },
          {
            state: {
              ...locationState,
              formattedPhoneNumber,
              mfaType: values["mfa-type"],
            },
          }
        )
      }
    },
    onError: handleSendMfaError,
  })

  const { handleChange, handleSubmit, values, isSubmitting } = useForm({
    initialValues: {
      "mfa-type":
        !preferredMfaType || (!email && preferredMfaType === MfaType.Email)
          ? MfaType.Sms
          : preferredMfaType,
    },
    onSubmit: (values) => {
      setPreferredMfaType(values["mfa-type"])
      /**
       * Refresh the open tabs to handle edge case of coming from a different domain
       */
      refreshOpenTabs()
      if (isLegacyRegistration) {
        sendMfaLegacy.mutate()
      } else {
        sendMfa.mutate({ email, mfaType: values["mfa-type"] })
      }
    },
  })

  React.useEffect(
    function emailOnlyUserSkipToConfirmPage() {
      if (isEmailOnlyUser) {
        authModel.resendMfaCode({ email, mfaType: MfaType.Email })
        navigate(
          {
            pathname: routeNames.confirmIdentityEmail,
            search,
          },
          {
            state: {
              ...locationState,
              isError: false,
            },
          }
        )
      }
    },
    // Navigating without an effect, or passing locationState as a dependency to this effect will break a test
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, isEmailOnlyUser, navigate, search]
  )
  if (isEmailOnlyUser) {
    return null
  }

  return (
    <WorkflowCard>
      {errorModal}
      <Medium24Header4
        css={css`
          padding-top: 16px;
          width: 100%;
        `}
      >
        {formatMessage({ id: "confirmIdentity.heading" })}
      </Medium24Header4>
      <Regular14Paragraph>
        {formatMessage({ id: "confirmIdentity.info" })}
      </Regular14Paragraph>
      <Form onSubmit={handleSubmit}>
        <RadioSheet
          listItems={[
            {
              value: MfaType.Sms,
              content: (
                <RadioItem>
                  <Semibold14>
                    {formatMessage({ id: "confirmIdentity.text" })}
                  </Semibold14>
                  <Regular14UI dir="ltr">{formattedPhoneNumber}</Regular14UI>
                </RadioItem>
              ),
              automationId: "mfa-options-text",
            },
            ...(!isLegacyRegistration && email
              ? [
                  {
                    value: MfaType.Email,
                    content: (
                      <RadioItem>
                        <Semibold14>
                          {formatMessage({ id: "confirmIdentity.email" })}
                        </Semibold14>
                        <Regular14UI dir="ltr">{email}</Regular14UI>
                      </RadioItem>
                    ),
                    automationId: "mfa-options-email",
                  },
                ]
              : []),
            {
              value: MfaType.Call,
              content: (
                <RadioItem>
                  <Semibold14>
                    {formatMessage({ id: "confirmIdentity.call" })}
                  </Semibold14>
                  <Regular14UI dir="ltr">{formattedPhoneNumber}</Regular14UI>
                </RadioItem>
              ),
              automationId: "mfa-options-call",
            },
          ]}
          renderContentTypography={(content) => content}
          value={values["mfa-type"]}
          name="mfa-type"
          onChange={(e) => {
            if (!isSubmitting) {
              handleChange(e)
            }
          }}
          scrollable={false}
        />
        <Button
          disabled={isSubmitting}
          variant="primary"
          height="medium"
          css={css`
            margin-top: 32px;
            width: 100%;
            max-width: 325px;

            ${breakpoints("xs")} {
              max-width: 325px;
              min-width: 0;
            }
          `}
          type="submit"
          data-automationid="mfa-options-next"
        >
          {formatMessage({ id: "confirmIdentity.next" })}
        </Button>
      </Form>
    </WorkflowCard>
  )
}

export const element = <DomainRedirectWrapper />
