import React from "react"
import _Modal from "src/components/modal"
import { type Interpolation, css, type Theme } from "@emotion/react"
import styled from "@emotion/styled"
import Alert from "src/icons/deprecated-alert"
import Warning from "src/icons/warning"
import breakpoints from "src/styles/breakpoints"
import Button from "src/components/buttons/button"
import { Medium14, Semibold20 } from "src/typography"

export type Variant = "error" | "warning" | "noicon"
const Modal = styled(_Modal)<{ variant?: Variant }>`
  width: 100%;
  height: 100%;
  #modal-popup {
    min-width: 275px;
    min-height: 145px;
    max-width: 423px;
    width: 100%;
    border-radius: ${(props) => props.theme.borderRadius};
    border: none;
    ${breakpoints("sm")} {
      max-width: 480px;
      width: unset;
      margin: 16px;
    }
    h4 {
      width: 95%;
      @supports (position: sticky) {
        // IE11 is pushing the header outside of the modal
        width: unset;
      }
      margin-top: 16px;
      font-style: normal;
      text-align: center;
      color: ${(props) => props.theme.palette.steel.eight};
    }

    > div > div > p {
      overflow-wrap: anywhere;
      text-align: center;
      margin: 8px 0 16px 0;
      color: ${(props) => props.theme.palette.steel.five};
      width: 100%;
    }
    > div > div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    ${(props) =>
      props.close &&
      css`
        > div {
          justify-content: unset;
        }
        display: flex;
        flex-direction: column;
        padding-top: 8px;
        padding-bottom: 14px;
      `};
  }
`

const Buttons = styled.div<{ multiple: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.multiple ? "space-between" : "center")};
  align-items: center;
  flex-direction: row !important;
  width: 300px;
  > button {
    // min-width is for back to summary text on data change modal (iPad in Xcode)
    min-width: fit-content;
    width: 140px;
  }
`
const IconContainer = styled.span`
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
/** @deprecated - use message-dialog */
const AlertModal = (props: {
  title?: React.ReactNode
  message?: React.ReactNode
  variant?: Variant
  onClose?: () => void
  onCloseText?: string | undefined
  onConfirm?: () => void
  onConfirmText?: string
  visible?: boolean
  disabled?: boolean
  messageCss?: Interpolation<Theme>
  /**
   * This should only be used with error boundaries
   */
  render?: () => React.ReactNode
}) => {
  const variant = props.variant ?? "error"
  const visible = props.visible ?? true
  if (props.render) {
    return <React.Fragment>{props.render()}</React.Fragment>
  }
  return (
    <Modal
      variant={variant}
      freezeBackground={true}
      close={props.disabled ? () => {} : props.onClose}
      visible={visible}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div>
        <div>
          {variant !== "noicon" && (
            <IconContainer>
              {variant === "error" && <Alert variant="large" />}
              {variant === "warning" && <Warning variant="large" />}
            </IconContainer>
          )}
          <Semibold20 id="modal-title">{props.title}</Semibold20>
        </div>
        {props.message && (
          <Medium14 id="modal-description" css={props.messageCss}>
            {props.message}
          </Medium14>
        )}
        <Buttons
          multiple={
            Boolean(props.onClose) &&
            Boolean(props.onConfirm) &&
            Boolean(props.onConfirmText)
          }
        >
          {props.onClose && (
            <Button
              height="small"
              disabled={props.disabled}
              variant={props.onConfirm ? "secondary" : "primary"}
              onClick={props.onClose}
            >
              {props.onCloseText ?? "OK"}
            </Button>
          )}
          {props.onConfirm && props.onConfirmText && (
            <Button
              variant="primary"
              height="small"
              disabled={props.disabled}
              onClick={props.onConfirm}
            >
              {props.onConfirmText}
            </Button>
          )}
        </Buttons>
      </div>
    </Modal>
  )
}

export default AlertModal
