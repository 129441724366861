import jsCookie from "js-cookie"
import { parseJwt } from "src/functions/parse-jwt"
import { getDeviceProperties } from "src/functions/get-device-properties"
import { GLOBAL_DOMAIN } from "src/constants"

export function prepareHttpRequestHeaders(type: "http" | "boomerang") {
  return {
    ...prepareDevicePropertiesHeaders(),
    ...prepareStudyIdRequestHeaders(),
    ...prepareSiteIdRequestHeaders(),
    ...(type === "http"
      ? { ...prepareBearerHeader(), ...prepareFrscHttpRequestHeaders() }
      : {}),
  }
}

export function processHttpResponseHeaders(
  headers: Headers,
  type: "http" | "boomerang"
) {
  if (type !== "boomerang") {
    // This specifically needs to happen BEFORE processJwtResponseHeaders
    processFrscResponseHeaders(headers)
    processJwtResponseHeaders(headers)
  }
}

// bearer backwards
const BEARER_KEY = "reraeb"
function prepareBearerHeader() {
  const bearer = jsCookie.get(BEARER_KEY)
  if (bearer) {
    return {
      Bearer: bearer,
    }
  }

  return {}
}
const CSRF_KEY = "frsc"
function prepareFrscHttpRequestHeaders() {
  const cookieFrsc = jsCookie.get(CSRF_KEY)
  let frsc_k
  let frsc_t
  if (cookieFrsc) {
    // Example cookie is frsc_k=12765041342037374717.frsc_t=43763642014465558991
    const [frsc_k_s, frsc_t_s] = cookieFrsc.split(".")

    const [, frsc_k_value] = frsc_k_s.split("=")
    const [, frsc_t_value] = frsc_t_s.split("=")
    // Set values into localStorage so that in the event the request that called this
    // is cancelled by page refresh, subsequent network requests can use these values
    localStorage.setItem("frsc_k", frsc_k_value)
    localStorage.setItem("frsc_t", frsc_t_value)
    frsc_k = frsc_k_value
    frsc_t = frsc_t_value
  }
  if (!frsc_k && !frsc_t) {
    frsc_k = localStorage.getItem("frsc_k")
    frsc_t = localStorage.getItem("frsc_t")
  }
  return {
    "x-frsc-h": frsc_k,
    [frsc_k || "THIS_IS_HERE_TO_WORK_ON_IE11"]: frsc_t,
  }
}

function prepareDevicePropertiesHeaders() {
  return {
    "device-properties": JSON.stringify(getDeviceProperties()),
  }
}

function prepareSiteIdRequestHeaders() {
  const globalStudySiteId = sessionStorage.getItem("globalStudySiteId")
  if (globalStudySiteId) {
    return {
      globalStudySiteId,
    }
  } else {
    return {}
  }
}
function prepareStudyIdRequestHeaders() {
  const globalSponsorStudyId = sessionStorage.getItem("globalSponsorStudyId")
  if (globalSponsorStudyId) {
    return { globalSponsorStudyId }
  } else {
    return {}
  }
}

function processFrscResponseHeaders(headers: Headers) {
  const frsc_t = headers.get("frsc_t")
  const frsc_k = headers.get("frsc_k")
  if (frsc_t && frsc_k) {
    localStorage.setItem("frsc_t", frsc_t)
    localStorage.setItem("frsc_k", frsc_k)
  }
}

function processJwtResponseHeaders(headers: Headers) {
  const jwt = headers.get("x-atkn")
  if (jwt) {
    const { sesttl, ver, sub } = parseJwt(jwt)
    /**
     * At this point, we know that if x-atkn has been returned by a network request
     * that the csrf and ac cookies should be set. Therefore we should remove any reraeb or
     * frsc cookies set.
     */
    jsCookie.remove(CSRF_KEY)
    // This cookie may have gotten set with or without a domain. So try to remove from both
    jsCookie.remove(CSRF_KEY, { domain: GLOBAL_DOMAIN })

    jsCookie.remove(BEARER_KEY)
    // This cookie may have gotten set with or without a domain. So try to remove from both
    jsCookie.remove(BEARER_KEY, { domain: GLOBAL_DOMAIN })
    if (sesttl) {
      localStorage.setItem(ver ? "myvp-sesttl" : "myvp-psesttl", sesttl)
    }
    if (sub) {
      localStorage.setItem("myvp-sub", sub)
    }
  }
}
