import { useQuery } from "@tanstack/react-query"
import languagesJson from "src/i18n/languages.json"
import { useI18nState } from "src/i18n/i18n-state-provider"
import defaultDateFnsLocale from "date-fns/locale/en-US"

export const getDecimalSeparator = (locale: Intl.LocalesArgument) =>
  // eslint-disable-next-line no-restricted-properties
  (1.1).toLocaleString(locale ?? "en-US").substring(1, 2)

export const getLocalizedDecimalString = (
  value: string | number,
  separator: string
) => String(value).replace(".", separator)

const SUPPORTED_LOCALES = languagesJson.shorthands
/**
 * Used currently for the "time ago" dates such as "6 hours ago"
 */
export const getDateFnsLanguageLocaleFile = async (
  locale: string
): Promise<import("date-fns").Locale | undefined> => {
  try {
    // Cannot do this dynamically or else all the chunks will be imported
    // @see https://date-fns.org/v2.30.0/docs/webpack for webpack specific solution
    // Note: when adding a new language - please verify by opening the typings in date-fns
    switch (locale) {
      // custom date-fns locales
      case SUPPORTED_LOCALES.asIN:
        return (await import("src/i18n/datetime/date-fns-locales/locale-asIN"))
          .default
      case SUPPORTED_LOCALES.mlIN:
        return (await import("src/i18n/datetime/date-fns-locales/locale-mlIN"))
          .default
      case SUPPORTED_LOCALES.mrIN:
        return (await import("src/i18n/datetime/date-fns-locales/locale-mrIN"))
          .default
      case SUPPORTED_LOCALES.nsoZA:
        return (await import("src/i18n/datetime/date-fns-locales/locale-nsoZA"))
          .default
      case SUPPORTED_LOCALES.orIN:
        return (await import("src/i18n/datetime/date-fns-locales/locale-orIN"))
          .default
      case SUPPORTED_LOCALES.paIN:
        return (await import("src/i18n/datetime/date-fns-locales/locale-paIN"))
          .default
      case SUPPORTED_LOCALES.tnZA:
        return (await import("src/i18n/datetime/date-fns-locales/locale-tnZA"))
          .default
      case SUPPORTED_LOCALES.urIN:
        return (await import("src/i18n/datetime/date-fns-locales/locale-urIN"))
          .default
      case SUPPORTED_LOCALES.xhZA:
        return (await import("src/i18n/datetime/date-fns-locales/locale-xhZA"))
          .default
      case SUPPORTED_LOCALES.zuZA:
        return (await import("src/i18n/datetime/date-fns-locales/locale-zuZA"))
          .default
      // end of custom date-fns locales
      case SUPPORTED_LOCALES.afZA:
        return (await import(`date-fns/locale/af/index.js`)).default
      case SUPPORTED_LOCALES.arIL:
        return (await import(`date-fns/locale/ar/index.js`)).default
      case SUPPORTED_LOCALES.bnIN:
        return (await import(`date-fns/locale/bn/index.js`)).default
      case SUPPORTED_LOCALES.enIL:
      case SUPPORTED_LOCALES.enSG:
      case SUPPORTED_LOCALES.enUS:
        return (await import(`date-fns/locale/en-US/index.js`)).default
      case SUPPORTED_LOCALES.enCA:
        return (await import(`date-fns/locale/en-CA/index.js`)).default
      case SUPPORTED_LOCALES.enIE:
        return (await import(`date-fns/locale/en-IE/index.js`)).default
      case SUPPORTED_LOCALES.enGB:
        return (await import(`date-fns/locale/en-GB/index.js`)).default
      case SUPPORTED_LOCALES.enAU:
        return (await import(`date-fns/locale/en-AU/index.js`)).default
      case SUPPORTED_LOCALES.enNZ:
        return (await import(`date-fns/locale/en-NZ/index.js`)).default
      case SUPPORTED_LOCALES.enZA:
        return (await import(`date-fns/locale/en-ZA/index.js`)).default
      case SUPPORTED_LOCALES.fiFI:
        return (await import(`date-fns/locale/fi/index.js`)).default
      case SUPPORTED_LOCALES.srRS:
        return (await import(`date-fns/locale/sr/index.js`)).default
      case SUPPORTED_LOCALES.srLatnRS:
        return (await import(`date-fns/locale/sr-Latn/index.js`)).default
      case SUPPORTED_LOCALES.caES:
        return (await import(`date-fns/locale/ca/index.js`)).default
      case SUPPORTED_LOCALES.csCZ:
        return (await import(`date-fns/locale/cs/index.js`)).default
      case SUPPORTED_LOCALES.deAT:
        return (await import(`date-fns/locale/de-AT/index.js`)).default
      case SUPPORTED_LOCALES.deBE:
      case SUPPORTED_LOCALES.deCH:
      case SUPPORTED_LOCALES.deDE:
        return (await import(`date-fns/locale/de/index.js`)).default
      case SUPPORTED_LOCALES.esAR:
      case SUPPORTED_LOCALES.esCL:
      case SUPPORTED_LOCALES.esCO:
      case SUPPORTED_LOCALES.esES:
      case SUPPORTED_LOCALES.esPE:
      case SUPPORTED_LOCALES.esUS:
      case SUPPORTED_LOCALES.esMX:
        return (await import(`date-fns/locale/es/index.js`)).default
      case SUPPORTED_LOCALES.frBE:
      case SUPPORTED_LOCALES.frFR:
        return (await import(`date-fns/locale/fr/index.js`)).default
      case SUPPORTED_LOCALES.frCA:
        return (await import(`date-fns/locale/fr-CA/index.js`)).default
      case SUPPORTED_LOCALES.frCH:
        return (await import(`date-fns/locale/fr-CH/index.js`)).default
      case SUPPORTED_LOCALES.guIN:
        return (await import(`date-fns/locale/gu/index.js`)).default
      case SUPPORTED_LOCALES.heIL:
        return (await import(`date-fns/locale/he/index.js`)).default
      case SUPPORTED_LOCALES.hiIN:
        return (await import(`date-fns/locale/hi/index.js`)).default
      case SUPPORTED_LOCALES.hrHR:
        return (await import(`date-fns/locale/hr/index.js`)).default
      case SUPPORTED_LOCALES.htHT:
        return (await import(`date-fns/locale/ht/index.js`)).default
      case SUPPORTED_LOCALES.huHU:
        return (await import(`date-fns/locale/hu/index.js`)).default
      case SUPPORTED_LOCALES.itCH:
      case SUPPORTED_LOCALES.itIT:
        return (await import(`date-fns/locale/it/index.js`)).default
      case SUPPORTED_LOCALES.jaJP:
        return (await import(`date-fns/locale/ja/index.js`)).default
      case SUPPORTED_LOCALES.knIN:
        return (await import(`date-fns/locale/kn/index.js`)).default
      case SUPPORTED_LOCALES.koKR:
        return (await import(`date-fns/locale/ko/index.js`)).default
      case SUPPORTED_LOCALES.ltLT:
        return (await import(`date-fns/locale/lt/index.js`)).default
      case SUPPORTED_LOCALES.msSG:
        return (await import(`date-fns/locale/ms/index.js`)).default
      case SUPPORTED_LOCALES.nlBE:
        return (await import(`date-fns/locale/nl-BE/index.js`)).default
      case SUPPORTED_LOCALES.nlNL:
        return (await import(`date-fns/locale/nl/index.js`)).default
      case SUPPORTED_LOCALES.plPL:
        return (await import(`date-fns/locale/pl/index.js`)).default
      case SUPPORTED_LOCALES.ptBR:
        return (await import(`date-fns/locale/pt-BR/index.js`)).default
      case SUPPORTED_LOCALES.ptPT:
        return (await import(`date-fns/locale/pt/index.js`)).default
      case SUPPORTED_LOCALES.nbNO:
        return (await import(`date-fns/locale/nb/index.js`)).default
      case SUPPORTED_LOCALES.ruUA:
      case SUPPORTED_LOCALES.ruIL:
      case SUPPORTED_LOCALES.ruLT:
        return (await import(`date-fns/locale/ru/index.js`)).default
      case SUPPORTED_LOCALES.svFI:
      case SUPPORTED_LOCALES.svSE:
        return (await import(`date-fns/locale/sv/index.js`)).default
      case SUPPORTED_LOCALES.taIN:
      case SUPPORTED_LOCALES.taSG:
        return (await import(`date-fns/locale/ta/index.js`)).default
      case SUPPORTED_LOCALES.teIN:
        return (await import(`date-fns/locale/te/index.js`)).default
      case SUPPORTED_LOCALES.thTH:
        return (await import(`date-fns/locale/th/index.js`)).default
      case SUPPORTED_LOCALES.trTR:
        return (await import(`date-fns/locale/tr/index.js`)).default
      case SUPPORTED_LOCALES.ukUA:
        return (await import(`date-fns/locale/uk/index.js`)).default
      case SUPPORTED_LOCALES.zhCN:
      case SUPPORTED_LOCALES.zhSG:
        return (await import(`date-fns/locale/zh-CN/index.js`)).default
      case SUPPORTED_LOCALES.zhHK:
        return (await import(`date-fns/locale/zh-HK/index.js`)).default
      case SUPPORTED_LOCALES.zhTW:
        return (await import(`date-fns/locale/zh-TW/index.js`)).default
      case SUPPORTED_LOCALES.bgBG:
        return (await import(`date-fns/locale/bg/index.js`)).default
      case SUPPORTED_LOCALES.daDK:
        return (await import(`date-fns/locale/da/index.js`)).default
      case SUPPORTED_LOCALES.elGR:
        return (await import(`date-fns/locale/el/index.js`)).default
      default:
        // NOTE: this needs to return `undefined` to catch missing entries when new language support is added
        return undefined
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    // eslint-disable-next-line no-console
    console.error("Could not import date-fns locale file for locale: ", locale)
    // NOTE: this needs to return `undefined` to catch missing entries when new language support is added
    return undefined
  }
}
export const useDateFnsLanguageLocale = (localeOverride?: string) => {
  const { locale: uiLocale } = useI18nState()

  const locale = localeOverride ?? uiLocale

  const { data } = useQuery({
    queryKey: ["useDateFnsLanguageLocale", locale],
    queryFn: async () => {
      return (
        (await getDateFnsLanguageLocaleFile(locale)) ?? defaultDateFnsLocale
      )
    },
    placeholderData: (data) => data,
  })
  return { locale: data }
}
