/**
 * Type guard to determine if the value is not undefined and not null.
 *
 * Useful for narrowing an array type when using `.filter`.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const isDefined = <T>(value: T): value is T & {} => {
  return value !== undefined && value !== null
}

/**
 * Type guard to determine if a string value is in a typescript string enum.
 *
 * @example
 * ```ts
 * enum MyEnum {
 *   A = "abc",
 *   B = "bac",
 *   C = "cab",
 * }
 * {
 *   const value = "abc"
 *   if (isStringEnumValue(MyEnum)(value)) {
 *     value // this will have type `MyEnum.A`
 *   }
 * }
 * function myFn(value: string) {
 *   if (isStringEnumValue(MyEnum)(value)) {
 *     value // this will have type `MyEnum`
 *   }
 * }
 * ```
 */
export const isStringEnumValue =
  <T extends string>(enumType: Record<string, T>) =>
  (value: unknown): value is T => {
    return Object.values(enumType).includes(value as T)
  }

/**
 * Returns the property name on a typescript enum for an enum value.
 *
 * This is useful when trying to template an enum value into an i18n message key with type-safety.
 */
export const getEnumKey = <T, U extends string>(
  enumType: Record<U, T>,
  value: T
): U | undefined => {
  const entry = Object.entries(enumType).find((entry) => entry[1] === value)
  return entry?.[0] as U | undefined
}
