// Taken from https://github.com/reduxjs/redux/blob/master/src/types/actions.ts

import { DataTag } from "@tanstack/react-query"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Action<T = any> {
  type: T
}

/**
 * An Action type which accepts any other properties.
 * This is mainly for the use of the `Reducer` type.
 * This is not part of `Action` itself to prevent types that extend `Action` from
 * having an index signature.
 */
export interface AnyAction extends Action {
  // Allows any extra properties to be defined in an action.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [extraProps: string]: any
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Reducer<S = any, A extends Action = AnyAction> = (
  state: S | undefined,
  action: A
) => S

export interface CustomEvent {
  detail?: any
  type?: string
  target?:
    | HTMLInputElement
    | EventTarget
    | { name?: string; value?: unknown; checked?: boolean }
  preventDefault?: () => void
}

export enum HttpCode {
  Ok = "200",
  NoContent = "204",
  BadRequest = "400",
  Unauthorized = "401",
  Forbidden = "403",
  NotFound = "404",
  Conflict = "409",
  Gone = "410",
  PreconditionFailed = "412",
  UnsupportedMediaType = "415",
  Locked = "423",
  TooManyRequests = "429",
  ServerError = "500",
  ServiceUnavailable = "503",
  GatewayTimeout = "504",
}

/**
 * This is a useful base type for generic type arguments that should be functions.
 *
 * The benefit of using this over `Function` is that it prevents classes from being passed.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BaseFunction = (...args: any) => any

export type QueryOptionsData<T> = T extends (...args: any[]) => {
  queryKey: DataTag<unknown, infer Data>
}
  ? Data
  : T extends {
        queryKey: DataTag<unknown, infer Data>
      }
    ? Data
    : never

export type TaggedQueryKey<T> = DataTag<unknown, T> & unknown[]

/**
 * Stricter version of `Omit` from the ts stdlib.
 *
 * The keys generic argument (`K`) must be a key of `T` in this version.
 */
export type OmitStrict<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

/**
 * @see https://www.totaltypescript.com/concepts/the-prettify-helper
 */
export type Prettify<T> = {
  [K in keyof T]: T[K]
  // eslint-disable-next-line @typescript-eslint/ban-types
} & {}
