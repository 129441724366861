import React from "react"
import { css } from "@emotion/react"
import { Medium20, Regular16Paragraph } from "src/typography"
import Button from "src/components/buttons/button"
import BaseDialog from "src/components/base-dialog"
import Warning from "src/icons/warning"
import Alert from "src/icons/alert"

const MessageDialog = (props: {
  variant?: "warning" | "alert" | "message"
  confirmButtonVariant?: React.ComponentProps<typeof Button>["variant"]
  title: React.ReactNode
  message: React.ReactNode
  onClose?: () => void
  disabledConfirm?: boolean
  onConfirm?: () => void
  onConfirmText?: React.ReactNode
  onCloseText: React.ReactNode | false
  disabledClose?: boolean
  visible?: boolean
  closeButtonAutomationId?: string
  confirmButtonAutomationId?: string
}) => (
  <BaseDialog
    visible={props.visible ?? true}
    onCancel={(event) => {
      if (!props.onClose) {
        // prevent closing when escape key is pressed
        event.preventDefault()
      }
    }}
    onClose={props.onClose}
    dialogCss={css`
      word-break: break-word;
      width: min(496px, calc(100dvw - 48px));
      min-height: 176px;
      height: auto;
      max-height: calc(100dvh - 48px);
      padding: 24px;
    `}
  >
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 4px;
      `}
    >
      {getIcon(props.variant)}
      <Medium20>{props.title}</Medium20>
    </div>
    <Regular16Paragraph
      css={css`
        margin-block: 16px;
        text-align: start;
        text-overflow: ellipsis;
        overflow: hidden;
        overflow-wrap: anywhere;
      `}
    >
      {props.message}
    </Regular16Paragraph>
    <div
      css={css`
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax(0px, 1fr);
        column-gap: 16px;
        button {
          white-space: nowrap;
        }
      `}
    >
      {props.onCloseText !== false && !!props.onClose && (
        <Button
          variant="secondary"
          height="large"
          onClick={props.onClose}
          disabled={props.disabledClose}
          data-automationid={props.closeButtonAutomationId}
        >
          {props.onCloseText}
        </Button>
      )}
      {Boolean(props.onConfirm && props.onConfirmText) && (
        <Button
          variant={props.confirmButtonVariant ?? "primary"}
          height="large"
          disabled={props.disabledConfirm}
          onClick={props.onConfirm}
          data-automationid={props.confirmButtonAutomationId}
        >
          {props.onConfirmText}
        </Button>
      )}
    </div>
  </BaseDialog>
)

export default MessageDialog

const getIcon = (
  variant: React.ComponentProps<typeof MessageDialog>["variant"]
) => {
  switch (variant) {
    case "warning":
      return <Warning variant="medium" />
    case "alert":
      return <Alert variant="filled" size="medium" />
    case "message":
    default:
      return null
  }
}
