import React from "react"
import styled from "@emotion/styled"
import _ErrorMessage from "src/components/error-message"
import breakpoints from "src/styles/breakpoints"
import { KEYS } from "src/constants"
import { sleep } from "src/functions/sleep"
import { SerializedStyles, css } from "@emotion/react"
import { CustomEvent } from "src/types"
import { Semibold28, semibold28Css, semibold40Css } from "src/typography"

export enum CodeInputStatus {
  Success = "success",
  Error = "error",
  Default = "",
}

type CellType = "#" | "-"
export enum InputPattern {
  AlphaNumeric = "[a-zA-Z0-9]*",
  Alpha = "[a-zA-Z]*",
  Numeric = "[0-9]*",
}

export interface CodeInputProps {
  className?: string
  codeLength: number
  disabled?: boolean
  errorMessage?: string | React.ReactNode
  focusOnMount?: boolean
  inputLabel: string
  name: string
  onChange: (e: CustomEvent) => void
  reset?: boolean
  setReset?: (reset: boolean) => void
  status?: CodeInputStatus
  blurOnComplete?: boolean
  castToUpper?: boolean
  filterDashes?: boolean
  updateStateOnBackspace?: boolean
  mask?: CellType[]
  patterns?: { re: RegExp; string: InputPattern }[]
  breakpointOverrides?: SerializedStyles
}

const ErrorMessage = styled(_ErrorMessage)`
  margin: 10px 0px;
`
const InputBox = styled.input<{
  status: CodeInputStatus
  breakpointOverrides?: SerializedStyles
}>`
  // From https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px
      ${(props) => {
        return props.theme.palette.steel.one
      }}
      inset !important;
  }

  // Remove shadow on iOS safari
  -webkit-appearance: none;
  width: 56px;
  height: 64px;
  ${(props) => semibold40Css(props.theme)};
  color: ${(props) =>
    props.disabled
      ? props.theme.palette.steel.four
      : props.theme.palette.steel.eight};
  text-align: center;
  text-transform: uppercase;
  border: 1px solid ${(props) => props.theme.palette.steel.three};
  ${(props) =>
    props.status === "error" &&
    css`
      border: 1px solid ${props.theme.palette.red.dark.zero};
    `};
  ${(props) =>
    props.status === "success" &&
    css`
      border: 1px solid ${props.theme.palette.success.one};
    `};
  border-radius: 2px;
  background-color: ${(props) => props.theme.palette.steel.one};
  outline: none;
  margin-inline-end: 8px;
  :last-of-type {
    margin-inline-end: 0;
  }
  :focus {
    border: 1px solid ${(props) => props.theme.palette.primary.one};
  }
  padding: 0;

  ${(props) =>
    props.breakpointOverrides ??
    css`
      ${breakpoints("xs")} {
        width: 42px;
        height: 52px;
        margin-inline-end: 4px;
      }
    `}

  ${breakpoints("xs")} {
    ${(props) => props.breakpointOverrides ?? semibold28Css(props.theme)};
  }
`

const CodeInput = (props: CodeInputProps) => {
  const initialStatus = props.status ?? CodeInputStatus.Default
  const blurOnComplete = props.blurOnComplete ?? true
  const castToUpper = props.castToUpper ?? false
  const filterDashes = props.filterDashes ?? false
  const updateStateOnBackspace = props.updateStateOnBackspace ?? false
  const patterns =
    props.patterns ??
    Array.from(Array(props.codeLength)).map(() => ({
      re: /(^[0-9]$|^$)/,
      string: InputPattern.Numeric,
    }))
  const [codeObj, setCodeObj] = React.useState(
    Array(props.codeLength)
      .fill("")
      .reduce((acc, val, ind) => ({ ...acc, [ind]: val }), {})
  )

  React.useEffect(() => {
    if (props.reset) {
      setCodeObj(
        Array(props.codeLength)
          .fill("")
          .reduce((acc, val, ind) => ({ ...acc, [ind]: val }), {})
      )
      props.setReset?.(false)
    }
  }, [props, setCodeObj])

  const [status, setStatus] = React.useState(initialStatus)
  React.useEffect(() => {
    // Sync status if parent updates it
    setStatus(initialStatus)
  }, [initialStatus])

  const refArray = React.useRef(Array(props.codeLength).fill(null))
  React.useEffect(() => {
    if (refArray.current[0] && props.focusOnMount) {
      sleep(0).then(() => {
        refArray.current[0]?.focus()
      })
    }
  }, [props.focusOnMount])

  const checkError = () => {
    if (status === CodeInputStatus.Error) {
      setStatus(CodeInputStatus.Default)
      setCodeObj(
        Array(props.codeLength)
          .fill("")
          .reduce((acc, val, ind) => ({ ...acc, [ind]: val }), {})
      )
    }
  }

  const testPastedValue = (pastedValue: string) => {
    if (props.patterns) {
      for (let i = 0; i < Math.min(patterns.length, pastedValue.length); i++) {
        const re = patterns[i].re
        if (!re.test(pastedValue.charAt(i))) {
          return false
        }
      }
      return true
    } else {
      return /(^[0-9]+$)/.test(pastedValue)
    }
  }

  const inputMultipleFields = (pastedValue: string, index: number) => {
    if (testPastedValue(pastedValue)) {
      const pasteEnd = Math.min(props.codeLength, pastedValue.length)
      const pasteObj = pastedValue
        .substring(0, pasteEnd)
        .split("")
        .reduce((acc, val, ind) => ({ ...acc, [ind]: val }), {})
      const newCodeObj = { ...codeObj, ...pasteObj }
      setCodeObj(newCodeObj)

      const newValue = Object.values(newCodeObj).join("")
      if (newValue.length === props.codeLength) {
        refArray.current[index].blur()
        props.onChange({ target: { value: newValue, name: props.name } })
      } else {
        refArray.current[pasteEnd].focus()
      }
    }
  }
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const normalizedValue = e.target.value.normalize("NFKC")
    if (e.target.value.length === props.codeLength) {
      // This should only happen on android devices using google keyboard
      inputMultipleFields(normalizedValue, index)
    } else if (patterns[index].re.test(normalizedValue)) {
      // Only allow single digits
      const newCodeObj = {
        ...codeObj,
        [index]: normalizedValue,
      }
      setCodeObj(newCodeObj)
      const newValue = Object.values(newCodeObj).join("")
      props.onChange({ target: { value: newValue, name: props.name } })

      // If all fields are full then blur while submitting
      if (newValue.length === props.codeLength) {
        if (blurOnComplete) {
          refArray.current[index].blur()
        } else {
          return
        }
      }

      // Otherwise, move to next field
      else {
        refArray.current[Math.min(index + 1, props.codeLength - 1)].focus()
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    let target = e.target as HTMLInputElement
    if (e.key === KEYS.backspace) {
      e.preventDefault()
      const newCodeObj = { ...codeObj }

      // If the cursor is at the start of the box
      if (target.selectionStart === 0 && target.selectionEnd === 0) {
        const prevIndex = Math.max(index - 1, 0)
        newCodeObj[prevIndex] = ""
        refArray.current[prevIndex].focus()
      }

      // If the cursor is after the current number
      else {
        newCodeObj[index] = ""
      }

      setCodeObj(newCodeObj)
      if (updateStateOnBackspace) {
        const newValue = Object.values(newCodeObj).join("")
        props.onChange({ target: { value: newValue, name: props.name } })
      }
    } else if (e.key === KEYS.arrowLeft) {
      if (target.selectionStart === 0 && target.selectionEnd === 0) {
        e.preventDefault()
        const elt = refArray.current[Math.max(index - 1, 0)]
        elt.focus()
        elt.selectionStart = 1
        elt.selectionEnd = 1
      }
    } else if (e.key === KEYS.arrowRight) {
      if (
        target.selectionStart === 1 ||
        (target.selectionStart === 0 && codeObj[index] === "")
      ) {
        e.preventDefault()
        const elt = refArray.current[Math.min(index + 1, props.codeLength - 1)]
        elt.focus()
        elt.selectionStart = 0
        elt.selectionEnd = 0
      }
    }
  }

  const handlePaste = (e: React.ClipboardEvent, index: number) => {
    e.preventDefault()
    const pastedValue = e.clipboardData.getData("Text")
    if (filterDashes) {
      const filteredValue = pastedValue.replace(/-/g, "")
      inputMultipleFields(filteredValue, index)
    } else {
      inputMultipleFields(pastedValue, index)
    }
  }
  let inputs: React.ReactNode[] = []
  const keyboardInputMode = patterns.some(
    (pattern) => pattern.string !== InputPattern.Numeric
  )
    ? "text"
    : "numeric"
  if (props.mask) {
    let codeIndex = -1
    inputs = props.mask.map((cellType, index) => {
      if (cellType === "#") {
        codeIndex++
        const currentCodeIndex = codeIndex
        return (
          <InputBox
            breakpointOverrides={props.breakpointOverrides}
            id={`input-code-${currentCodeIndex}`}
            autoComplete="off"
            key={index}
            inputMode={keyboardInputMode}
            pattern={patterns[currentCodeIndex].string}
            type="text"
            data-testid={`code-input-${currentCodeIndex}`}
            ref={(el) => (refArray.current[currentCodeIndex] = el)}
            value={
              castToUpper && typeof codeObj[currentCodeIndex] === "string"
                ? codeObj[currentCodeIndex].toUpperCase()
                : codeObj[currentCodeIndex]
            }
            onChange={(e) => handleChange(e, currentCodeIndex)}
            status={status}
            onFocus={checkError}
            onKeyDown={(e) => handleKeyDown(e, currentCodeIndex)}
            onPaste={(e) => handlePaste(e, currentCodeIndex)}
            aria-label={props.inputLabel}
            disabled={props.disabled}
            data-automationid={`code-input-${currentCodeIndex + 1}`}
          />
        )
      } else {
        return (
          <Semibold28
            as="div"
            key={index}
            css={(theme) => css`
              color: ${theme.palette.foreground.default};
            `}
          >
            -
          </Semibold28>
        )
      }
    })
  } else {
    inputs = Array.from(Array(props.codeLength)).map((_, index) => (
      <InputBox
        breakpointOverrides={props.breakpointOverrides}
        id={`input-code-${index}`}
        autoComplete="off"
        key={index}
        inputMode={keyboardInputMode}
        pattern={patterns[index].string}
        type="text"
        data-testid={`code-input-${index}`}
        ref={(el) => (refArray.current[index] = el)}
        value={
          castToUpper && typeof codeObj[index] === "string"
            ? codeObj[index].toUpperCase()
            : codeObj[index]
        }
        onChange={(e) => handleChange(e, index)}
        status={status}
        onFocus={checkError}
        onKeyDown={(e) => handleKeyDown(e, index)}
        onPaste={(e) => handlePaste(e, index)}
        aria-label={props.inputLabel}
        disabled={props.disabled}
        data-automationid={`code-input-${index + 1}`}
      />
    ))
  }

  return (
    <>
      <div
        className={props.className}
        css={css`
          direction: ltr;
          ${props.mask &&
          css`
            display: flex;
            gap: 8px;
            align-items: center;
            input {
              margin-inline-end: 0;
            }
          `}
        `}
      >
        {inputs}
      </div>
      {status === CodeInputStatus.Error && (
        <ErrorMessage label={props.errorMessage} showIcon={false} />
      )}
    </>
  )
}

export default CodeInput
