import React from "react"
import Alert from "src/icons/deprecated-alert"
import styled from "@emotion/styled"
import { type Theme, css } from "@emotion/react"
import { Semibold14 } from "src/typography"
import Warning from "src/icons/warning"

const textColorCss = {
  error: (theme: Theme) => css`
    color: ${theme.palette.red.dark.zero};
  `,
  warning: (theme: Theme) => css`
    color: ${theme.palette.foreground.warning};
  `,
} as const
const ErrorLabel = styled(Semibold14)`
  line-height: 14px;
  font-size: 12px;
  margin: 0;
  padding: 0;
`
const IconContainer = styled.span`
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const newIconSpace = css`
  margin-inline-end: 8px;
`

const ErrorMessage = (allProps: {
  className?: string
  label?: string | string[] | React.ReactNode
  showIcon?: boolean
  useNewIcon?: boolean
  variant?: "error" | "warning"
}) => {
  const {
    label,
    showIcon = true,
    useNewIcon,
    variant = "error",
    ...props
  } = allProps

  let icon: React.ReactNode
  switch (variant) {
    case "error":
      icon = <Alert variant={useNewIcon ? "bold" : "small"} />
      break
    case "warning":
      icon = <Warning />
      break
  }

  return (
    <div {...props} role={label ? "alert" : undefined}>
      {label ? (
        <>
          {showIcon && (
            <IconContainer css={useNewIcon && newIconSpace}>
              {icon}
            </IconContainer>
          )}
          <ErrorLabel
            data-automationid="field-error-text"
            css={textColorCss[variant]}
          >
            {label}
          </ErrorLabel>
        </>
      ) : (
        ""
      )}
    </div>
  )
}

export default styled(ErrorMessage)`
  display: flex;
  align-items: center;
  min-height: 18px;
`
