import React from "react"
import DeprecatedButton from "@myvp/shared/src/components/deprecated-button"
import styled from "@emotion/styled"
import useForm, { FormState } from "@myvp/shared/src/hooks/use-form"
import { css } from "@emotion/react"
import { useIntl } from "react-intl"
import CodeInput, {
  CodeInputStatus,
} from "@myvp/shared/src/components/code-input"
import Lottie from "react-lottie-player"
import enterCodeSuccess from "src/animations/enter-code-success.json"
import { sleep } from "@myvp/shared/src/functions/sleep"
import useDidUpdate from "@myvp/shared/src/hooks/use-did-update"
import { CODE_INPUT_ANIMATION_TIME } from "src/constants"
import useStorageState from "@myvp/shared/src/hooks/use-storage-state"
import useInterval from "@myvp/shared/src/hooks/use-interval"
import { addSeconds, differenceInSeconds } from "date-fns"
import ErrorMessage from "@myvp/shared/src/components/error-message"
import useRefCallback from "@myvp/shared/src/hooks/use-ref-callback"
import useResizeObserver from "@myvp/shared/src/hooks/use-resize-observer"
import { MfaType } from "src/types"
import type { MfaFormValues } from "src/pages/enter-mfa"
import { Regular14Paragraph } from "@myvp/shared/src/typography"
import { useMutation } from "@tanstack/react-query"
import { getErrorCode } from "@myvp/shared/src/functions/get-error-code"
import { HttpCode } from "@myvp/shared/src/types"
import { PhoneErrorModal } from "src/components/phone-mfa-form"
import { type I18nMessageId } from "src/i18n/i18n-message-types"

const ResendMessage = styled.div<{ centerContent?: boolean }>`
  margin-bottom: 16px;
  margin-top: 4px;
  min-height: 92px;
  display: flex;
  align-items: flex-end;

  p {
    // Need top margin here for IE11
    margin-top: 68px;
    color: ${(props) => props.theme.palette.steel.seven};
  }

  ${(props) =>
    props.centerContent &&
    css`
      justify-content: center;
    `}

  ${ErrorMessage} p {
    // Need top margin here for IE11
    margin-top: 68px;
    @supports (position: sticky) {
      margin-top: 0;
    }

    ${(props) =>
      props.centerContent &&
      css`
        text-align: center;
      `}
  }
`
const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  ${DeprecatedButton} {
    max-width: none;
    height: 48px;
  }
`
const Form = styled.div`
  width: 100%;
`

const LottieContainer = styled.div<{ formWidth: number; formHeight: number }>`
  display: flex;
  align-items: center;
  div {
    min-width: ${(props) => props.formWidth}px;
    width: 100%;
    min-height: ${(props) => props.formHeight}px;
    height: ${(props) => props.formHeight}px;
  }
`
export const useSend = (
  args: {
    api: () => Promise<any>
    setErrors?: (errors: FormState<MfaFormValues>["errors"]) => void
    onError?: (error: Error) => void
    setResetCodeInput?: React.Dispatch<React.SetStateAction<boolean>>
    tickOnMount: boolean
    time?: number
  } & (
    | { type: MfaType; setType?: (type: MfaType) => void }
    | { storageKey: string }
  )
) => {
  const [isFetching, setIsFetching] = React.useState(false)
  const sendApi = useMutation({
    mutationKey: ["sendMfa"],
    mutationFn: args.api,
    onSuccess: () => {
      setMfaSentDate(
        addSeconds(
          Date.now(),
          Number(process.env.REACT_APP_mfa_countdown_timer)
        ).getTime()
      )

      if ("setType" in args) {
        args.setType?.(args.type)
      }
      setIsFetching(false)
    },
    onError: (e) => {
      if (args.onError) {
        args.onError(e)
      }
    },
    throwOnError: false,
  })
  const setResetCodeInputRef = useRefCallback(
    args.setResetCodeInput ?? (() => {})
  )
  const setErrorsRef = useRefCallback(args.setErrors ?? (() => {}))

  let storageKey
  if ("storageKey" in args) {
    storageKey = args.storageKey
  } else {
    storageKey = `mfa-sent-timer:${args.type}`
  }

  const [mfaSentDate, setMfaSentDate, removeMfaSentDate] = useStorageState(
    () => {
      if (args.tickOnMount) {
        return addSeconds(
          Date.now(),
          Number(process.env.REACT_APP_mfa_countdown_timer)
        ).getTime()
      } else {
        return 0
      }
    },
    storageKey
  )

  const [timer, setTimer] = React.useState(
    Math.max(differenceInSeconds(mfaSentDate, Date.now()), 0)
  )
  useInterval(
    () => setTimer(differenceInSeconds(mfaSentDate, Date.now())),
    1000,
    {
      active: differenceInSeconds(mfaSentDate, Date.now()) >= 0,
      runImmediately: true,
    }
  )

  const invoke = async () => {
    setIsFetching(true)
    setErrorsRef.current?.({})
    setResetCodeInputRef.current?.(true)
    sendApi.mutate()
  }
  return {
    disabled: timer > 0,
    isFetching,
    invoke,
    timer,
    setMfaSentDate,
    removeValues: () => {
      removeMfaSentDate()
    },
  }
}

const EnterMfaForm = (props: {
  centerContent?: boolean
  children?: (props: {
    isSubmitting: boolean
    component: React.ReactElement
    setErrors: (errors: FormState<MfaFormValues>["errors"]) => void
    animating: boolean
    header: string
    type: MfaType
    message: string
  }) => React.ReactChild
  className?: string
  disabled?: boolean
  onSubmit: (
    values: MfaFormValues,
    options: {
      setSubmitting: (submitting: boolean) => void
      setErrors: (errors: FormState<MfaFormValues>["errors"]) => void
      stop: ({
        errors,
        isSubmitting,
        hasChanges,
        blurred,
      }: {
        errors?: FormState<MfaFormValues>["errors"]
        isSubmitting?: boolean
        hasChanges?: boolean
        blurred?: FormState<MfaFormValues>["blurred"]
      }) => void
      animate: () => Promise<void>
      type: MfaType
    }
  ) => void
  resend: (type: MfaType) => Promise<void>
  initialMfaType?: MfaType
}) => {
  const centerContent = props.centerContent ?? false
  const disabled = props.disabled ?? false
  const initialMfaType = props.initialMfaType ?? MfaType.Sms
  const { formatMessage } = useIntl()
  const [errorCode, setErrorCode] = React.useState<HttpCode | null>(null)
  const [resetCodeInput, setResetCodeInput] = React.useState(false)

  const CODE_LENGTH = 6
  const { handleChange, isSubmitting, errors, setErrors, animating } = useForm({
    validateOnChange: true,
    animationTime: CODE_INPUT_ANIMATION_TIME,
    submitListener: (values) => {
      return values.code.length === CODE_LENGTH
    },
    initialValues: { code: "" },
    validate: (values, { type }) => {
      let errors: FormState<MfaFormValues>["errors"] = {}
      if (!values.code && errors.validationErrors) {
        errors.validationErrors.code = true
      }
      if (type === "submit") {
        if (values.code.length !== 6 || !/[0-9]{6,6}/g.test(values.code)) {
          errors.submitError = formatMessage({
            id: "enterMfa.invalidCode",
          })
        }
      }
      return errors
    },
    onSubmit: (values, { setSubmitting, setErrors, stop, animate }) => {
      props.onSubmit(values, {
        setSubmitting,
        setErrors,
        stop,
        animate,
        type,
      })
    },
  })

  const [type, setType, removeType] = useStorageState(
    initialMfaType,
    "mfa-type"
  )

  const text = useSend({
    type: MfaType.Sms,
    setType,
    api: () => props.resend(MfaType.Sms),
    setErrors,
    setResetCodeInput,
    tickOnMount: true,
    onError: (e) => {
      setErrorCode(getErrorCode(e) as HttpCode)
    },
  })

  const call = useSend({
    type: MfaType.Call,
    setType,
    api: () => props.resend(MfaType.Call),
    setErrors,
    setResetCodeInput,
    tickOnMount: true,
    onError: (e) => {
      setErrorCode(getErrorCode(e) as HttpCode)
    },
  })

  const {
    height: formHeight,
    width: formWidth,
    ref: formRef,
  } = useResizeObserver<HTMLDivElement>({
    preserveOnDisappear: true,
  })

  const [showingSuccessStatus, setShowingSuccessStatus] = React.useState(true)
  useDidUpdate(animating, (prevAnimating) => {
    if (!prevAnimating && animating) {
      sleep(300).then(() => {
        setShowingSuccessStatus(false)
        // Cleanup session storage interval when animation completes
        text.removeValues()
        call.removeValues()
        removeType()
      })
    }
  })
  let status =
    showingSuccessStatus && animating
      ? CodeInputStatus.Success
      : CodeInputStatus.Default
  if (errors.submitError) {
    status = CodeInputStatus.Error
  }

  let component
  if (!showingSuccessStatus && animating) {
    component = (
      <LottieContainer formHeight={formHeight} formWidth={formWidth}>
        <div>
          <Lottie animationData={enterCodeSuccess} play />
        </div>
      </LottieContainer>
    )
  } else {
    let sendCodeDisabled = text.disabled || isSubmitting || disabled
    let voiceCallDisabled = call.disabled || isSubmitting || disabled

    let resendButtonMessage = formatMessage({ id: "enterMfa.resend" })
    if (voiceCallDisabled && !sendCodeDisabled) {
      sendCodeDisabled = true
      // If voice call is disabled AND send code is not happening, just disable the send another code button, don't allow text changes to happen
    } else if (sendCodeDisabled) {
      resendButtonMessage = formatMessage(
        { id: "enterMfa.sent" },
        { time: text.timer }
      )
    }
    let voiceCallMessage = formatMessage({ id: "enterMfa.getVoiceCall" })
    if (voiceCallDisabled) {
      voiceCallMessage = formatMessage(
        { id: "enterMfa.calling" },
        { time: call.timer }
      )
    } else if (type === MfaType.Call) {
      voiceCallMessage = formatMessage({ id: "enterMfa.getAnotherCall" })
    }

    component = (
      <Form ref={formRef} className={props.className}>
        <CodeInput
          css={css`
            display: flex;
            justify-content: space-between;
            input {
              width: 15%;
              margin-inline-end: 4px;
            }
          `}
          codeLength={CODE_LENGTH}
          name="code"
          errorMessage={errors.submitError}
          reset={resetCodeInput}
          setReset={setResetCodeInput}
          status={status}
          inputLabel={formatMessage({ id: "enterMfa.enterMFACode" })}
          onChange={handleChange}
          focusOnMount
          disabled={isSubmitting || disabled}
        />
        <>
          <ResendMessage centerContent={centerContent}>
            {(() => {
              if (type === MfaType.Sms && !sendCodeDisabled) {
                return (
                  <Regular14Paragraph>
                    {formatMessage({ id: "enterMfa.didntGetTheCode" })}
                  </Regular14Paragraph>
                )
              }

              if (type === MfaType.Call && !voiceCallDisabled) {
                return (
                  <Regular14Paragraph>
                    {formatMessage({ id: "enterMfa.didntGetTheCallCode" })}
                  </Regular14Paragraph>
                )
              }
            })()}
          </ResendMessage>
          <Buttons>
            <DeprecatedButton
              variant="outlined"
              disabled={sendCodeDisabled}
              color="default"
              onClick={text.invoke}
              type="button"
              data-automationid={
                !call.disabled ? "get-another-call" : undefined
              }
            >
              {resendButtonMessage}
            </DeprecatedButton>
            <DeprecatedButton
              variant="outlined"
              color="default"
              type="button"
              disabled={voiceCallDisabled}
              onClick={call.invoke}
              data-automationid={(() => {
                if (!voiceCallDisabled && type === MfaType.Call) {
                  return "get-another-call"
                } else if (!voiceCallDisabled && type !== MfaType.Call) {
                  return "get-a-voice-call"
                }
              })()}
            >
              {voiceCallMessage}
            </DeprecatedButton>
          </Buttons>
        </>
      </Form>
    )
  }
  let header
  let message: I18nMessageId
  if (type === MfaType.Call) {
    header = "enterMfa.titleVoiceCall"
    message = "enterMfa.voiceCallMessage"
  } else {
    header = "enterMfa.title"
    message = "enterMfa.phoneNumberMessage"
  }

  return (
    <React.Fragment>
      <PhoneErrorModal
        errorCode={errorCode}
        onClose={() => setErrorCode(null)}
      />
      {props.children
        ? props.children({
            isSubmitting,
            component,
            setErrors,
            animating,
            header,
            type,
            message,
          })
        : component}
    </React.Fragment>
  )
}

export default styled(EnterMfaForm)``
