import React from "react"
import styled from "@emotion/styled"
import { css, useTheme } from "@emotion/react"

type Variant =
  | "default"
  | "success"
  | "medium-success"
  | "primary"
  | "bold"
  | "large-default"
  | "large-primary"
  | "completed"
  | "extra-large"
const Svg = styled.svg<{
  variant: Variant
}>`
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;

  path {
    stroke: ${(props) => props.theme.palette.foreground.inverse};
  }

  ${(props) =>
    props.variant === "default" &&
    css`
      circle {
        fill: ${props.theme.palette.foreground.inverse};
        stroke: ${props.theme.palette.foreground.default};
      }
      path {
        stroke: ${props.theme.palette.foreground.default};
      }
    `}

  ${(props) =>
    props.variant === "bold" &&
    css`
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;

      circle {
        fill: ${props.theme.palette.foreground.success};
      }
    `}


  ${(props) =>
    props.variant === "success" &&
    css`
      circle {
        fill: ${props.theme.palette.foreground.success};
      }
    `}

  ${(props) =>
    props.variant === "medium-success" &&
    css`
      min-width: 24px;
      width: 24px;
      min-height: 24px;
      height: 24px;

      path {
        stroke: none;
      }
    `}

  ${(props) =>
    props.variant === "large-default" &&
    css`
      min-width: 32px;
      width: 32px;
      min-height: 32px;
      height: 32px;
      circle,
      path {
        stroke: ${props.theme.palette.foreground.default};
      }
    `}

  ${(props) =>
    props.variant === "large-primary" &&
    css`
      min-width: 32px;
      width: 32px;
      min-height: 32px;
      height: 32px;
      circle {
        fill: ${props.theme.palette.foreground.highlight};
      }
      path {
        stroke: ${props.theme.palette.foreground.inverse};
        stroke-width: 1.5;
      }
    `}
    ${(props) =>
    props.variant === "completed" &&
    css`
      min-width: 24px;
      width: 24px;
      min-height: 24px;
      height: 24px;
      circle {
        fill: ${props.theme.palette.surface.success.default};
      }
      path {
        stroke: ${props.theme.palette.foreground.success};
        fill: ${props.theme.palette.foreground.success};
      }
    `}
  ${(props) =>
    props.variant === "extra-large" &&
    css`
      min-width: 54px;
      min-height: 54px;
    `}
`
const CheckCircle = (props: {
  variant?: Variant
  className?: string
  r?: string
}) => {
  // Set to success as default because this was the first variant introduced.
  const variant = props.variant ?? "success"
  const r = props.r ?? "9"
  const theme = useTheme()
  const linearId = React.useId()
  let viewBox = "0 0 20 20"
  let children = (
    <>
      <circle cx="10" cy="10" r={r} strokeWidth="2" />
      <path d="M5 10.5L8.33333 14L15 7" />
    </>
  )

  if (variant === "bold") {
    viewBox = "0 0 16 16"
    children = (
      <>
        <circle cx="8" cy="8" r="8" />
        <path d="M4.5 8L7 10.5L12 5.5" strokeWidth="2" />
      </>
    )
  } else if (variant === "large-default") {
    viewBox = "0 0 32 32"
    children = (
      <>
        <circle cx="16" cy="16" r="11" strokeWidth="2" />
        <path d="M10 16.5999L14 20.7999L22 12.3999" strokeWidth="1.5" />
      </>
    )
  } else if (variant === "primary") {
    viewBox = "0 0 25 25"
    children = (
      <>
        <circle cx="12.75" cy="12" r="12" fill={`url(#${linearId})`} />
        <path d="M6.75 12.6L10.75 16.8L18.75 8.40002" strokeWidth="1.5" />
        <defs>
          <linearGradient
            id={`url(#${linearId})`}
            x1="24.75"
            y1="24"
            x2="0.75"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={theme.palette.foreground.highlight} />
            <stop offset="1" stopColor={theme.palette.primary.two} />
          </linearGradient>
        </defs>
      </>
    )
  } else if (variant === "large-primary") {
    viewBox = "0 0 32 32"
    children = (
      <>
        <circle cx="16" cy="16" r="12" />
        <path d="M10 16.6004L14 20.8004L22 12.4004" strokeWidth="1.5" />
      </>
    )
  } else if (variant === "completed") {
    viewBox = "0 0 24 24"
    children = (
      <>
        <circle cx="12" cy="12" r="10" />
        <path d="M11.4709 15.6916L17.2219 9.69155L15.778 8.30762L10.7011 13.6044L8.67275 11.7598L7.32715 13.2394L10.0762 15.7394L10.7968 16.3948L11.4709 15.6916Z" />
      </>
    )
  } else if (variant === "extra-large") {
    viewBox = "0 0 54 54"
    children = (
      <>
        <circle
          cx="27"
          cy="27"
          r="22.125"
          fill={theme.palette.foreground.success}
          stroke={theme.palette.foreground.success}
          strokeWidth="3"
        />
        <path
          d="M15.1875 27.8438L23.0625 35.4375L38.8125 20.25"
          stroke={theme.palette.surface.default}
          strokeWidth="3"
        />
      </>
    )
  } else if (variant === "medium-success") {
    viewBox = "0 0 24 24"
    children = (
      <>
        <circle
          cx="12.5"
          cy="12"
          r="10"
          fill={theme.palette.foreground.success}
        />
        <path
          d="M11.9709 15.6919L17.7219 9.69192L16.278 8.30798L11.2011 13.6047L9.17275 11.7601L7.82715 13.2398L10.5762 15.7398L11.2968 16.3952L11.9709 15.6919Z"
          fill={theme.palette.foreground.inverse}
        />
      </>
    )
  }

  return (
    <Svg
      focusable="false"
      variant={variant}
      className={props.className}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
      <defs>
        <linearGradient
          id={linearId}
          x1="18"
          y1="16.8"
          x2="10.1074"
          y2="5.52486"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.palette.foreground.highlight} />
          <stop offset="1" stopColor={theme.palette.primary.two} />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default styled(CheckCircle)``
