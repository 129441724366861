import { css } from "@emotion/react"
import React from "react"
import { Link } from "react-router-dom"
import { type IconColor } from "src/styles/icon-utils"
import { Theme } from "src/styles/theme"

type ButtonVariant = "primary" | "secondary" | "tertiary" | "destructive"

export interface CoreButtonProps {
  className?: string
  isAnchorOpen?: boolean
  variant: ButtonVariant
  height: "small" | "medium" | "large"
}

export const InternalLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<typeof Link>
>(function InternalButtonLink(props, ref) {
  return (
    <Link
      {...props}
      ref={ref}
      className={props.className}
      onClickCapture={(event) => {
        if (
          props["aria-disabled"] === true ||
          props["aria-disabled"] === "true"
        ) {
          event.preventDefault()
        } else {
          props.onClickCapture?.(event)
        }
      }}
    >
      {"children" in props && props.children}
    </Link>
  )
})

export const InternalButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button">
>(function InternalButton(props, ref) {
  return (
    <button
      {...props}
      ref={ref}
      className={props.className}
      onClick={(event) => {
        if (
          props["aria-disabled"] === true ||
          props["aria-disabled"] === "true"
        ) {
          event.preventDefault()
        } else {
          props.onClick?.(event)
        }
      }}
    >
      {"children" in props && props.children}
    </button>
  )
})

export const getButtonIconColor = (args: {
  variant: ButtonVariant
  iconColor?: IconColor // Only for tertiary variant
  disabled?: boolean
}): IconColor => {
  switch (args.variant) {
    case "primary":
      return "inverse"
    case "secondary":
      return args.disabled ? "disabled" : "default"
    case "tertiary":
      if (args.disabled) {
        return "disabled"
      } else if (args.iconColor) {
        return args.iconColor
      } else {
        return "link"
      }
    case "destructive":
      return args.disabled ? "disabled" : "critical"
  }
}

/** `xsmall` is for icon buttons only */
export const heightStyles = {
  xsmall: css`
    height: 24px;
    min-height: 24px;
  `,
  small: css`
    height: 32px;
    min-height: 32px;
  `,
  medium: css`
    height: 40px;
    min-height: 40px;
  `,
  large: css`
    height: 44px;
    min-height: 44px;
  `,
}

export const activeCss = (forceActive: boolean) => {
  if (forceActive) {
    return css`
      background-color: var(--activeColor);
    `
  }
}

const secondary = (theme: Theme) => css`
  --activeColor: ${theme.palette.surface.pressed};
  background-color: ${theme.palette.surface.default};
  border: 1px solid ${theme.palette.border.default};
  color: ${theme.palette.foreground.default};

  :hover {
    background-color: ${theme.palette.surface.hovered};
  }

  :active {
    background-color: var(--activeColor);
  }

  :disabled,
  &[aria-disabled="true"] {
    cursor: not-allowed;
    background-color: ${theme.palette.surface.disabled};
    color: ${theme.palette.foreground.disabled};
    border: none;
  }
`
export const variantStyles = {
  primary: (theme: Theme) => css`
    --activeColor: ${theme.palette.surface.brand.pressed};
    background-color: ${theme.palette.surface.brand.default};
    border: none;
    color: ${theme.palette.foreground.inverse};

    :hover {
      background-color: ${theme.palette.surface.brand.hovered};
    }

    :active {
      background-color: var(--activeColor);
    }

    :disabled,
    &[aria-disabled="true"] {
      cursor: not-allowed;
      background-color: ${theme.palette.surface.brand.disabled};
    }
  `,
  secondary,
  destructive: [
    secondary,
    (theme: Theme) => css`
      color: ${theme.palette.foreground.critical};
    `,
  ],
  tertiary: (theme: Theme) => css`
    --activeColor: ${theme.palette.surface.pressed};
    color: ${theme.palette.foreground.link};
    border: none;
    background: none;

    :hover {
      background-color: ${theme.palette.surface.hovered};
    }

    :active {
      background-color: var(--activeColor);
    }

    :disabled,
    &[aria-disabled="true"] {
      cursor: not-allowed;
      color: ${theme.palette.foreground.disabled};
      background-color: unset;
    }
  `,
}
