import AlertModal from "@myvp/shared/src/components/deprecated-alert-modal"
import { HttpCode } from "@myvp/shared/src/types"
import React from "react"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { routeNames } from "src/router/route-names"
import { useErrorBoundary } from "@myvp/shared/src/hooks/use-error-boundary"

export default function VisitErrorBoundary() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const errorConfigs = {
    [HttpCode.NotFound]: {
      title: formatMessage({ id: "todo.visitNotFoundTitle" }),
      message: formatMessage({ id: "todo.visitNotFoundMessage" }),
      onClose: () =>
        navigate({
          pathname: routeNames.todo,
          search: location.search,
        }),
      onCloseText: formatMessage({ id: "todo.ok" }),
    },
    fallback: {
      title: formatMessage({ id: "todo.somethingWentWrong" }),
      message: formatMessage({ id: "error.internalServerErrorRefresh" }),
      onCloseText: formatMessage({ id: "alertModal.ok" }),
    },
  }

  const errorConfig = useErrorBoundary(errorConfigs)
  return <AlertModal {...errorConfig} />
}
